import store from "../store/index";

export const sessionCheck = async (loggedIn) => {
  return store
    .dispatch("auth/isSessionActive", { user_login: loggedIn.user.email })
    .then((res) => {
      store.dispatch("emc/resetSessionTimePassed");
      return res.session;
    }).catch(error => {return;});
};

export const sessionEndLogout = async (session, next) => {
  if (!session)
    return store.dispatch("auth/logout").then(() => next({ name: "login" }));
};

export const getUser = () => {
  return store.getters["auth/user"];
};
