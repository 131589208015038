import userPermissionsService from "../../services/user-permsissions.service";



const selectedUserData = localStorage.getItem("permissionsUserData");
const localStorageSelectedUser = selectedUserData ? JSON.parse(JSON.parse((selectedUserData))) : {};



const userPermissionsInitializeState = {
  APIUsers: null,
  APITables: null,
  APIColumns: null,
  apiColumnSelectedTable: [],
  APIUserPermissions:null,
  
  selectedUser: localStorageSelectedUser,
  toastData: {
    message: "",
    title: "",
    type: "",
  },
  usersLoading: true,
};

export const userPermissions = {
  namespaced: true,
  state: userPermissionsInitializeState,
  actions: {
    getAPIUsers({ commit },data) {
      commit("setUsersLoading", true);
      return userPermissionsService
        .getAPIUsers(data.maintainer)
        .then(
          (APIUsers) => {
            commit("getAPIUsersSuccess", APIUsers.data.users);
            return Promise.resolve(APIUsers);
          },
          (error) => {
            commit("getAPIUsersFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateToastData", {
            title: "Error!",
            msg: errorMsg,
            type: "error",
          });
        });
    },
    updateAPIUser({ commit }, data) {
        return userPermissionsService.updateAPIUser(data)
          .then(
            (result) => {
              commit("updateToastData", {
                title: "Success!",
                msg: "Data update success",
                type: "success",
              });
              return Promise.resolve(result);
            },
            (error) => {
              return Promise.reject(error);
            }
          )
          .catch((error) => {
            const errorMsg = error.response.data.message;
            const validationErrors = error.response.data.errors;
            commit("updateToastData", {
              title: "Error!",
              msg: errorMsg,
              errors: validationErrors,
              type: "error",
            });
          });
      },
      createAPIUser({ commit } ,data) {
        return userPermissionsService.createAPIUser(data)
          .then(
            (result) => {
              commit("updateToastData", {
                title: "Success!",
                msg: "Permissions create success",
                type: "success",
              });
              return Promise.resolve(result);
            },
            (error) => {
              return Promise.reject(error);
            }
          )
          .catch((error) => {
            const errorMsg = error.response.data.message;
            const validationErrors = error.response.data.errors;
            commit("updateToastData", {
              title: "Error!",
              msg: errorMsg,
              errors: validationErrors,
              type: "error",
            });
          });
      },
      setUsersLoading({ commit }, loading) {
        commit("setUsersLoading", loading);
      },
      selectUser({ commit }, user) {
        commit("selectUser", user);
      },
      getAPITables({ commit },data) {
        return userPermissionsService
          .getAPITables(data.maintainer)
          .then(
            (APITables) => {
              commit("getAPITablesSuccess", APITables.data.tables);
              return Promise.resolve(APITables);
            },
            (error) => {
              commit("getAPITablesFailure");
              return Promise.reject(error);
            }
          )
          .catch((error) => {
            const errorMsg = error.response.data.message;
            commit("updateToastData", {
              title: "Error!",
              msg: errorMsg,
              type: "error",
            });
          });
      },
      getAPIUserPermissions({ commit },data) {
        return userPermissionsService
          .getAPIUserPermissions(data.maintainer, data.user_id)
          .then(
            (APIUserPermissions) => {
              commit("getAPIUserPermissionsSuccess", APIUserPermissions.data.permissions);
              return Promise.resolve(APIUserPermissions);
            },
            (error) => {
              commit("getAPIUserPermissionsFailure");
              return Promise.reject(error);
            }
          )
          .catch((error) => {
            const errorMsg = error.response.data.message;
            commit("updateToastData", {
              title: "Error!",
              msg: errorMsg,
              type: "error",
            });
          });
      },
      getAPIColumns({ commit },data) {
        return userPermissionsService
          .getAPIColumns(data.maintainer, data.table_name, data.imo)
          .then(
            (APIColumns) => {
              commit("getAPIColumnsSuccess", APIColumns.data.columns);
              return Promise.resolve(APIColumns);
            },
            (error) => {
              commit("getAPIColumnsFailure");
              return Promise.reject(error);
            }
          )
          .catch((error) => {
            const errorMsg = error.response.data.message;
            commit("updateToastData", {
              title: "Error!",
              msg: errorMsg,
              type: "error",
            });
          });
      },
      createAPIUserPermissions({ commit } ,data) {
        return userPermissionsService.createAPIUserPermissions(data)
          .then(
            (result) => {
              commit("updateToastData", {
                title: "Success!",
                msg: "Permissions create success",
                type: "success",
              });
              return Promise.resolve(result);
            },
            (error) => {
              return Promise.reject(error);
            }
          )
          .catch((error) => {
            const errorMsg = error.response.data.message;
            commit("updateToastData", {
              title: "Error!",
              msg: errorMsg,
              type: "error",
            });
          });
      },
      updateAPIUserPermissions({ commit }, data) {
        return userPermissionsService.updateAPIUserPermissions(data)
          .then(
            (result) => {
              commit("updateToastData", {
                title: "Success!",
                msg: "Data update success",
                type: "success",
              });
              return Promise.resolve(result);
            },
            (error) => {
              return Promise.reject(error);
            }
          )
          .catch((error) => {
            const errorMsg = error.response.data.message;
            commit("updateToastData", {
              title: "Error!",
              msg: errorMsg,
              type: "error",
            });
          });
      },
      clearAPIColumns({ commit }) {
        commit("clearAPIColumns");
      },
  },
  mutations: {
    getAPIUsersSuccess(state, APIUsers) {
      state.APIUsers = APIUsers;
    },

    getAPIUsersFailure(state) {
      state.APIUsers = null;
    },
    updateToastData(state, data) {
      if (data) {
        state.toastData = {
          title: data.title,
          message: data.msg,
          type: data.type,
          errors:data.errors,
        };
      } else {
        state.toastData = {};
      }
    },
    setUsersLoading(state, loading) {
      state.usersLoading = loading;
    },
    selectUser(state, user) {
      state.selectedUser = user;
    },
    getAPITablesSuccess(state, APITables) {
      state.APITables = APITables;
    },
    getAPITablesFailure(state) {
      state.APITables = null;
    },
    getAPIColumnsSuccess(state, APIColumns) {
      state.APIColumns = APIColumns;
    },
    getAPIColumnsFailure(state) {
      state.APIColumns = null;
    },
    getAPIUserPermissionsSuccess(state, APIUserPermissions) {
      state.APIUserPermissions = APIUserPermissions;
    },
    getAPIUserPermissionsFailure(state) {
      state.APIUserPermissions = null;
    },
    setApiColumnSelectedTable(state, selectedTable) {
      state.apiColumnSelectedTable
        ? (state.apiColumnSelectedTable = [
            ...state.apiColumnSelectedTable,
            ...selectedTable,
          ])
        : (state.apiColumnSelectedTable = [...selectedTable]);
    },
    clearAPIColumns(state) {
      state.APIColumns = [];
    },
  },
  getters: {
    APIUsers: (state) => {
      return state.APIUsers;
    },
    toastData: (state) => {
      return state.toastData;
    },
  usersLoading: (state) => {
    return state.usersLoading;
  },
  selectedUser: (state) => {
    return state.selectedUser;
  },
  APITables: (state) => {
    return state.APITables;
    
  },
  APIColumns: (state) => {
    return state.APIColumns;
  },
  apiColumnSelectedTable: (state) => {
    return state.APITablesColumn ;
  },
  APIUserPermissions: (state) => {
    return state.APIUserPermissions;
  },
}
};
