<template>
  <div
    class="test p-col-5"

  >
    <EditWatchdogKpiExclusionForm
      v-if="showAddNewExclusionForm"
      :state_="state"
      :exclusions="exclusions.filter(x => x.id != state.id)"
      :show-delete-button="showDeleteButton"
      :show-close-btn="showCloseBtn"
      @hideNewExclusionForm="hideNewExclusionForm"
    ></EditWatchdogKpiExclusionForm>

    <WatchdogKpiExclusionsTable
      v-else
      @editExclusion="editExclusion($event)"
      @showEditExclusionForm="showEditExclusionForm($event)"
      :exclusions="exclusions"
      :loading="loading"
    ></WatchdogKpiExclusionsTable>
  </div>
</template>

<script>
import {onMounted, ref, computed, reactive, getCurrentInstance, watch} from "vue";
import {FilterMatchMode} from "primevue/api";
import {useStore} from "vuex";
import dayjs from "dayjs";
import EditWatchdogKpiExclusionForm from "../forms/EditWatchdogKpiExclusionForm";
import WatchdogKpiExclusionsTable from "../tables/WatchdogKpiExclusionsTable";
import {createToast} from "../../functions/utils";
import {useToast} from "primevue/usetoast";

export default {
  name: "WachdogKpiExclusions",
  props:{
    ownerId: null,
    shipImo: null,
    maintainer: null
  },
  components: {WatchdogKpiExclusionsTable, EditWatchdogKpiExclusionForm},

  setup(props){

    const toast = useToast();

    const state = reactive({
      ownerId: props.ownerId,
      shipImo: props.shipImo,
      id: null,
      maintainer: props.maintainer
    });

    const showAddNewExclusionForm = ref(false);
    const showDeleteButton = ref(false);
    const showCloseBtn = ref(false);

    const errorMessage = computed(() => store.getters['kpi/errorMessage'])
    const exclusionModificationStatus = computed(() => store.getters['kpi/exclusionStatus'])
    const store = useStore();
    const loading = ref(true);

    const exclusions = computed(() => store.getters["kpi/watchdogKpiExcludions"]);

    const clearState_ = () => {
      state.id = null;
      state.description = null;
      state.dateFrom = convertDateToUTC(dayjs().toDate());
      state.dateTo = null;
    }

    const showEditExclusionForm = (clearState) => {
      if(clearState){
        clearState_();
        showDeleteButton.value = false;
        showCloseBtn.value = false;
      }
      else{
        showDeleteButton.value = true;
        showCloseBtn.value = state.dateTo == null;
      }
      showAddNewExclusionForm.value = true;
    }

    const hideNewExclusionForm = () => {
      showAddNewExclusionForm.value = false;
    }

    const convertDateToUTC = (date) => {
      return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      );
    };

    const editExclusion = (exclusion) => {
      state.dateFrom = dayjs(exclusion.start_time).toDate();
      state.dateTo = exclusion.end_time ? dayjs(exclusion.end_time).toDate() : null;
      state.description = exclusion.description;
      state.id = exclusion.id;

      showEditExclusionForm();
    }

    onMounted(() => {

      let params = {
        ownerId: props.ownerId,
        shipImo: props.shipImo
      }

      store.dispatch("kpi/getWatchdogKpiExclusions", params).then(() => {
        loading.value = false;
      });
    })

    watch(errorMessage, () => {
      if(errorMessage.value !== ""){
        createToast(
            toast,
            "error",
            "Error!",
            errorMessage.value
        );
      }
      store.dispatch("kpi/clearErrorMsg");
    })

    watch(exclusionModificationStatus, () => {
      if(exclusionModificationStatus.value.message !== ""){
        createToast(
          toast,
          exclusionModificationStatus.value.type,
          exclusionModificationStatus.value.title,
          exclusionModificationStatus.value.message
        );
      }
    })

    return{
      loading,
      exclusions,
      showAddNewExclusionForm,
      state,
      showDeleteButton,
      showCloseBtn,

      hideNewExclusionForm,
      showEditExclusionForm,
      editExclusion,
      dayjs
    }
  },
}
</script>

<style>

.kpi-exclusions-table{
  max-height: auto;
}

.kpi-exclusions-table tr{
  height: 55px !important;
}

.current-exclusion{
  color: red;
  font-weight: bold;
}

.p-inputgroup{
  width: auto;
}

.add-new-exclusion-btn{
 width: 100px;
  text-align: center;
  margin-right: 20px;
}

@media only screen and (min-width: 1640px){
  .kpi-exclusions-table{
    //max-height: 350px;
    max-height: 100%;
  }
}

.edit-exclusion-form{
  margin: 10px 50px 10px;
}

.edit-exclusion-form textarea {
  display:block;
  width: 100%;
  margin-bottom: 10px;
  height: 60px;
  border-radius: 3px;
}

.p-button-success, .p-button-danger, .p-button-info{
  width: 100%;
  margin-bottom: 10px;
}
</style>
