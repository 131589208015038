import RdmService from "../../services/rdm.service";

const rdmInitialState = {
  rdmAllUsers: [],
  rdmUserShips: {
    owner_enamor: {},
    owner_wingd: {},
    ships: [],
    user: {},
  },
  rdmEditUserData: {},
  rdmOwnerShips: {},
  rdmUserSelectedShips: [],
  rdmApiErrorMsg: "",
};

export const rdm = {
  namespaced: true,
  state: rdmInitialState,
  actions: {
    getRdmAllUsers({ commit }) {
      return RdmService.getRdmAllUsers()
        .then(
          (rdmAllUsers) => {
            commit("getRdmAllUsersSuccess", rdmAllUsers);
            return Promise.resolve(rdmAllUsers);
          },
          (error) => {
            commit("getRdmAllUsersFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateRdmApiErrorMsg", errorMsg);
        });
    },

    refreshRdmAllUsers({ commit }) {
      return RdmService.refreshRdmAllUsers()
        .then(
          (refreshedAllUsers) => {
            commit("getRdmAllUsersSuccess", refreshedAllUsers);
            return Promise.resolve(refreshedAllUsers);
          },
          (error) => {
            commit("getRdmAllUsersFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateRdmApiErrorMsg", errorMsg);
        });
    },

    getRdmUserShips({ commit }, id) {
      return RdmService.getRdmUserShips(id)
        .then(
          (rdmUserShips) => {
            commit("getRdmUserShipsSuccess", rdmUserShips);
            return Promise.resolve(rdmUserShips);
          },
          (error) => {
            commit("getRdmUserShipsFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateRdmApiErrorMsg", errorMsg);
        });
    },

    getRdmOwnerShips({ commit }, params) {
      return RdmService.getRdmOwnerShips(params)
        .then(
          (rdmOwnerShips) => {
            commit("getRdmOwnerShipsSuccess", rdmOwnerShips);
            return Promise.resolve(rdmOwnerShips);
          },
          (error) => {
            commit("getRdmOwnerShipsFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateRdmApiErrorMsg", errorMsg);
        });
    },

    refreshOwners({ commit }) {
      return RdmService.refreshOwners()
        .then(
          (refreshedOwners) => {
            commit("refreshOwnersSuccess", refreshedOwners);
            return Promise.resolve(refreshedOwners);
          },
          (error) => {
            commit("refreshOwnersFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateRdmApiErrorMsg", errorMsg);
        });
    },

    getRdmNewUser({ commit }) {
      return RdmService.getRdmNewUser()
        .then(
          (rdmNewUserData) => {
            commit("getRdmNewUserSuccess", rdmNewUserData);
            return Promise.resolve(rdmNewUserData);
          },
          (error) => {
            commit("getRdmNewUserFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateRdmApiErrorMsg", errorMsg);
        });
    },

    createRdmUser({ commit }, rdmNewUserData) {
      return RdmService.createRdmUser(rdmNewUserData)
        .then(
          (rdmNewUserDataFromAPI) => {
            commit("createRdmUserSuccess", rdmNewUserDataFromAPI);
            return Promise.resolve(rdmNewUserDataFromAPI);
          },
          (error) => {
            commit("createRdmUserFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateRdmApiErrorMsg", errorMsg);
        });
    },

    updateRdmUser({ commit }, rdmUpdateUserData) {
      return RdmService.updateRdmUser(rdmUpdateUserData)
        .then(
          (rdmUpdateUserDataFromAPI) => {
            commit("updateRdmUserSuccess", rdmUpdateUserDataFromAPI);
            return Promise.resolve(rdmUpdateUserDataFromAPI);
          },
          (error) => {
            commit("updateRdmUserFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateRdmApiErrorMsg", errorMsg);
        });
    },

    updateRdmUserShips({ commit }, rdmUpdateUserShips) {
      return RdmService.updateRdmUserShips(rdmUpdateUserShips)
        .then(
          (rdmUpdateUserShipsFromAPI) => {
            commit("updateRdmUserShipsSuccess", rdmUpdateUserShipsFromAPI);
            return Promise.resolve(rdmUpdateUserShipsFromAPI);
          },
          (error) => {
            commit("updateRdmUserShipsFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateRdmApiErrorMsg", errorMsg);
        });
    },

    deleteRdmUser({ commit }, id) {
      return RdmService.deleteRdmUser(id)
        .then(
          (rdmDeletedUser) => {
            return Promise.resolve(rdmDeletedUser);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateRdmApiErrorMsg", errorMsg);
        });
    },
  },
  mutations: {
    getRdmAllUsersSuccess(state, rdmAllUsers) {
      state.rdmAllUsers = rdmAllUsers.data;
    },
    getRdmAllUsersFailure(state) {
      state.rdmAllUsers = null;
    },

    getRdmUserShipsSuccess(state, rdmUserShips) {
      state.rdmUserShips = rdmUserShips.data;
    },
    getRdmUserShipsFailure(state) {
      state.rdmUserShips = null;
    },

    getRdmOwnerShipsSuccess(state, rdmOwnerShips) {
      state.rdmOwnerShips = rdmOwnerShips.data;
    },
    getRdmOwnerShipsFailure(state) {
      state.rdmOwnerShips = null;
    },

    setRdmUserSelectedShips(state, selectedShips) {
      state.rdmUserSelectedShips
        ? (state.rdmUserSelectedShips = [
            ...state.rdmUserSelectedShips,
            ...selectedShips,
          ])
        : (state.rdmUserSelectedShips = [...selectedShips]);

      state.rdmUserSelectedShips
        ? (state.rdmUserSelectedShips = state.rdmUserSelectedShips.filter(
            (v, i, a) =>
              a.findIndex((t) => t.owner_id === v.owner_id && t.id === v.id) ===
              i
          ))
        : null;
    },

    refreshOwnersSuccess(state, refreshedOwners) {
      state.rdmUserShips.owner_enamor = refreshedOwners.data.owner_enamor;
      state.rdmUserShips.owner_wingd = refreshedOwners.data.owner_wingd;
    },

    refreshOwnersFailure() {
      console.error("refresh owners procedure was not executed");
    },

    getRdmNewUserSuccess(state, rdmNewUserData) {
      state.rdmUserShips = {
        owner_enamor: rdmNewUserData.data.owner_enamor,
        owner_wingd: rdmNewUserData.data.owner_wingd,
        ships: [],
        user: {
          login: "",
          authorized: false,
          password: "",
          firstName: "",
          secondName: "",
          email: "",
          expireDate: null,
          maintainer: "",
          owner: "",
        },
      };
    },
    getRdmNewUserFailure(state) {
      state.rdmUserShips = null;
    },

    createRdmUserSuccess(state, rdmNewUserDataFromAPI) {
      state.rdmEditUserData = rdmNewUserDataFromAPI.data;
    },
    createRdmUserFailure(state) {
      state.rdmEditUserData = null;
    },

    updateRdmUserSuccess(state, rdmUpdateUserDataFromAPI) {
      state.rdmEditUserData = rdmUpdateUserDataFromAPI.data;
    },
    updateRdmUserFailure(state) {
      state.rdmEditUserData = null;
    },

    updateRdmUserShipsSuccess(state, rdmUpdateUserShipsFromAPI) {
      state.rdmEditUserData = rdmUpdateUserShipsFromAPI.data;
    },
    updateRdmUserShipsFailure(state) {
      state.rdmEditUserData = null;
    },
    updateRdmApiErrorMsg(state, errorMsg) {
      state.rdmApiErrorMsg = errorMsg;
    },
  },
  getters: {
    rdmAllUsers: (state) => {
      return state.rdmAllUsers && state.rdmAllUsers;
    },

    rdmUserData: (state) => {
      return state.rdmUserShips && state.rdmUserShips.user;
    },

    rdmOwnerEnamor: (state) => {
      const rdmOwnerEnamor =
        state.rdmUserShips && state.rdmUserShips.owner_enamor;
      return convert(rdmOwnerEnamor);
    },
    rdmOwnerWinGD: (state) => {
      const rdmOwnerWinGD =
        state.rdmUserShips && state.rdmUserShips.owner_wingd;
      return convert(rdmOwnerWinGD);
    },

    rdmUserSelectedShips: (state) => {
      return state.rdmUserShips && state.rdmUserShips.ships;
    },

    rdmApiErrorMsg: (state) => {
      return state.rdmApiErrorMsg && state.rdmApiErrorMsg;
    },
  },
};

const convert = (object) =>
  Object.entries(object).map(([key, value]) => ({
    ["id"]: value.id.toString(),
    ["name"]: value.owner_name,
  }));
