import KpiService from "../../services/kpi.service";
import TicketsService from "@/services/tickets.service";

const kpiInitialState = {
  dashboardList: {},
  serverList: {},
  kpiResultsType: "total",
  dataIsLoading: false,

  dateBetween: {},
  dateBetweenReal: {},

  selectedKpi: "",
  selectedKpiDisplayName: "DETAILS",
  selectedMaintainer: null,
  selectedOwnerId: null,
  selectedShipImo: null,
  selectedShipId: null,

  responseKpiData: [],
  importerKpiData: [],
  yearKPIPDFData: [],
  activationTimeKpiData: [],

  watchdogKpiData: [],
  watchdogShipKpiData: [],
  watchdogShipsKpiByOwner: [],
  watchdogKpiExcludions: [],
  watchdogShipServiceStatuses: [],

  kpiErrorMsg: "",
  kpiExclusionModificationStatus: {
    message: "",
    title: "",
    type: ""
  },
  toastData: {
    message: "",
    title: "",
    type: ""
  }
};

export const kpi = {
  namespaced: true,
  state: kpiInitialState,
  actions: {
    updateDate({ commit }, date) {
      commit("updateDate", date);
    },
    selectShip({ commit }, data) {
      commit("selectShip", data);
    },
    selectKpi({ commit }, kpi) {
      commit("selectKpi", kpi);
    },
    selectKpiDisplayName({ commit }, displayName) {
      commit("selectKpiDisplayName", displayName);
    },
    udpateTicketId({ commit }, tickets) {
      commit("tickets", tickets);
    },

    getDashboardList({ commit, state }, params) {
      return KpiService.getDashboardList()
        .then(
          (dashboardList) => {
            commit("dashboardList", dashboardList);
            return Promise.resolve(dashboardList);
          },
          (error) => {
            const errorMsg = error.response.data.message;
            commit("dashboardListFailure");
            commit("updateKpiErrorMsg", errorMsg);
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateKpiErrorMsg", errorMsg);
          commit("dashboardListFailure");
        });
    },
    getDashboardKpi({ commit, state }, params) {
      return KpiService.getDashboardKpi(params)
        .then(
          (result) => {
            return Promise.resolve(result.data);
          },
          (error) => {
            const errorMsg = error.response.data.message;
            commit("updateKpiErrorMsg", errorMsg);
            return Promise.reject();
          }
        )
        .catch((error) => {
        });
    },
    getServerList({ commit, state }, maintainer) {
      return KpiService.getServerList(maintainer)
        .then(
          (serverList) => {
            commit("serverList", serverList);
            return Promise.resolve(serverList);
          },
          (error) => {
            const errorMsg = error.response.data.message;
            commit("serverListFailure");
            commit("updateKpiErrorMsg", errorMsg);
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateKpiErrorMsg", errorMsg);
          commit("serverListFailure");
        });
    },
    getServerKpi({ commit, state }, params) {
      return KpiService.getServerKpi(params)
        .then(
          (result) => {
            return Promise.resolve(result.data);
          },
          (error) => {
            const errorMsg = error.response.data.message;
            commit("updateKpiErrorMsg", errorMsg);
            return Promise.reject();
          }
        )
        .catch((error) => {
        });
    },
    getResponseTimeKpi({ commit }, params) {
      return KpiService.getResponseTimeKpi(params)
        .then(
          (kpiParams) => {
            commit("getResponseTimeKpiSuccess", kpiParams);
            commit("setResponseTimeKPIType");
            commit("saveDatesOptions", kpiParams);
            commit("setDates");
            return Promise.resolve(kpiParams);
          },
          (error) => {
            commit("getResponseTimeKpiFailure");
            commit("saveDatesOptionsFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateKpiErrorMsg", errorMsg);
        });
    },
    getImporterKpi({ commit }, params) {
      return KpiService.getImporterKpi(params)
        .then(
          (importerKpiParams) => {
            commit("getImporterKpiSuccess", importerKpiParams);
            return Promise.resolve(importerKpiParams);
          },
          (error) => {
            commit("getImporterKpiFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateKpiErrorMsg", errorMsg);
        });
    },

    getYearKPIPDF({ commit }, params) {
      return KpiService.getYearKPIPDF(params)
        .then(
          (yearKPIPDFParams) => {
            commit("getYearKPIPDFSuccess", yearKPIPDFParams);
            return Promise.resolve(yearKPIPDFParams);
          },
          (error) => {
            commit("getYearKPIPDFFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateKpiErrorMsg", errorMsg);
        });
    },

    getActivationTimeKpi({ commit }, params) {
      return KpiService.getActivationTimeKpi(params)
        .then(
          (activationKpiParams) => {
            commit("getActivationTimeKpiSuccess", activationKpiParams);
            commit("setActivationTimeKpiType");
            commit("saveDatesOptions", activationKpiParams);
            commit("setDates");
            return Promise.resolve(activationKpiParams);
          },
          (error) => {
            commit("getActivationTimeKpiFailure");
            commit("saveDatesOptionsFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateKpiErrorMsg", errorMsg);
        });
    },
    getWatchdogShipsKpiByOwner({ commit }, params) {
      return KpiService.getWatchdogShipsKpiByOwner(params)
        .then(
          (watchdogKpiParams) => {
            commit("getWatchdogShipsKpiByOwnerSuccess", watchdogKpiParams);
            commit("setWatchdogShipsKpiByOwnerKPIType");
            commit("saveDatesOptions", watchdogKpiParams);
            commit("setDates");
            return Promise.resolve(watchdogKpiParams);
          },
          (error) => {
            commit("getWatchdogShipsKpiByOwnerFailure");
            commit("saveDatesOptionsFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateKpiErrorMsg", errorMsg);
        });
    },
    getWatchdogShipKpiData({ commit }, params) {
      return KpiService.getWatchdogShipKpi(params)
        .then(
          (watchdogKpiParams) => {
            commit("getWatchdogShipKpiDataSuccess", watchdogKpiParams);
            return Promise.resolve(watchdogKpiParams);
          },
          (error) => {
            commit("getWatchdogShipKpiDataFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateKpiErrorMsg", errorMsg);
        });
    },
    getWatchdogKpiExclusions({ commit }, kpiParams) {
      return KpiService.getWatchdogKpiExclusions(kpiParams)
        .then(
          (watchdogKpiParams) => {
            commit("getWatchdogKpiExclusionsSuccess", watchdogKpiParams);
            return Promise.resolve(watchdogKpiParams);
          },
          (error) => {
            commit("getWatchdogKpiExclusionsFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateKpiErrorMsg", errorMsg);
        });
    },
    createWatchdogKpiExclusion({ commit }, kpiParams) {
      return KpiService.createWatchdogKpiExclusions(kpiParams)
        .then(
          (watchdogKpiParams) => {
            commit("updateExclusionStatus", {
              title: "Success!",
              msg: "Exclusion create success",
              type: "success"
            });
            return Promise.resolve(watchdogKpiParams);
          },
          (error) => {
            commit("updateExclusionStatus", {
              title: "Error!",
              msg: "Exclusion update failed",
              type: "error"
            });
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateKpiErrorMsg", errorMsg);
        });
    },
    updateWatchdogKpiExclusion({ commit }, kpiParams) {
      return KpiService.updateWatchdogKpiExclusions(kpiParams)
        .then(
          (watchdogKpiParams) => {
            if (kpiParams.deleted == 1) {
              commit("updateExclusionStatus", {
                title: "Success!",
                msg: "Exclusion delete success",
                type: "info"
              });
            } else {
              commit("updateExclusionStatus", {
                title: "Success!",
                msg: "Exclusion update success",
                type: "info"
              });
            }
            return Promise.resolve(watchdogKpiParams);
          },
          (error) => {
            commit("updateExclusionStatus", {
              title: "Error!",
              msg: "Exclusion update failed",
              type: "error"
            });
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateKpiErrorMsg", errorMsg);
        });
    },
    createServicesExclusions({ commit }, params) {
      return KpiService.createServicesExclusions(params)
        .then(
          (watchdogKpiParams) => {
            commit("updateExclusionStatus", {
              title: "Success!",
              msg: "Exclusion creation success",
              type: "success"
            });
            return Promise.resolve(watchdogKpiParams);
          },
          (error) => {
            commit("updateExclusionStatus", {
              title: "Error!",
              msg: "Exclusion creation fail",
              type: "error"
            });
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateKpiErrorMsg", errorMsg);
        });
    },
    clearKpiName({ commit }) {
      commit("clearKpiName");
    },
    setMaintainer({ commit }, maintainer) {
      commit("setMaintainer", maintainer);
    },
    clearErrorMsg({ commit }) {
      commit("updateKpiErrorMsg", "");
    },
    clearExclusionStatus({ commit }) {
      commit("updateExclusionStatus", null);
    },
    getTorquemeterFaultDetection({ commit }, params) {
      return KpiService.getTorquemeterFaultDetection(params)
        .then(
          (result) => {
            commit("updateToastData", {
              title: "Success!",
              msg: result.data.message,
              type: "success"
            });
            return Promise.resolve(result);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateToastData", {
            title: "Error!",
            msg: errorMsg,
            type: "error"
          });
          return Promise.reject(error);
        });
    }
  },
  mutations: {
    selectShip(state, data) {
      state.selectedShipId = data.shipId;
      state.selectedShipImo = data.shipImo;
      state.selectedOwnerId = data.ownerId;
    },
    selectKpi(state, kpi) {
      state.selectedKpi = kpi;
    },

    dashboardList(state, dashboardList) {
      dashboardList.data.result.forEach((item) => {
        item.loaded = false;
        item.visible = false;
      });
      state.dashboardList = dashboardList.data.result;
      state.isDashboard = true;
    },
    dashboardListFailure(state) {
      state.dashboardList = [];
    },
    serverList(state, serverList) {
      serverList.data.result.forEach((item) => {
        item.loaded = false;
        item.visible = false;
      });
      state.serverList = serverList.data.result;
      state.selectedKpiDisplayName =
        serverList.data.kpi_name ?? "DETAILS";
    },
    serverListFailure(state) {
      state.serverList = [];
    },
    setKpiResultsType(state, newKpiResultsType) {
      state.kpiResultsType = newKpiResultsType;
      this.commit("kpi/setDates");
      this.commit("kpi/setWatchdogShipsKpiByOwnerKPIType");
      this.commit("kpi/setResponseTimeKPIType");
      this.commit("kpi/setActivationTimeKpiType");
    },
    setDates(state) {
      if (state.kpiResultsType === "sla") {
        state.dateBetweenReal.startTime = state.dateBetweenReal.sla_startTime ?? undefined;
        state.dateBetweenReal.endTime = state.dateBetweenReal.sla_endTime ?? undefined;
      } else if (state.kpiResultsType === "nosla") {
        state.dateBetweenReal.startTime = state.dateBetweenReal.nosla_startTime ?? undefined;
        state.dateBetweenReal.endTime = state.dateBetweenReal.nosla_endTime ?? undefined;
      } else {
        state.dateBetweenReal.startTime = state.dateBetweenReal.total_startTime ?? undefined;
        state.dateBetweenReal.endTime = state.dateBetweenReal.total_endTime ?? undefined;
      }
    },
    saveDatesOptions(state, result) {
      state.dateBetweenReal.total_startTime =
        result.data.total_start_time ?? undefined;
      state.dateBetweenReal.total_endTime =
        result.data.total_end_time ?? undefined;
      state.dateBetweenReal.sla_startTime =
        result.data.sla_start_time ?? undefined;
      state.dateBetweenReal.sla_endTime =
        result.data.sla_end_time ?? undefined;
      state.dateBetweenReal.nosla_startTime =
        result.data.nosla_start_time ?? undefined;
      state.dateBetweenReal.nosla_endTime =
        result.data.nosla_end_time ?? undefined;
    },
    saveDatesOptionsFailure(state) {
      state.dateBetweenReal.total_startTime = undefined;
      state.dateBetweenReal.total_endTime = undefined;
      state.dateBetweenReal.sla_startTime = undefined;
      state.dateBetweenReal.sla_endTime = undefined;
      state.dateBetweenReal.nosla_startTime = undefined;
      state.dateBetweenReal.nosla_endTime = undefined;
    },
    setWatchdogShipsKpiByOwnerKPIType(state) {
      for (let i = 0; i < state.watchdogShipsKpiByOwner.length; i++) {
        if (state.kpiResultsType === "sla") {
          state.watchdogShipsKpiByOwner[i].kpi =
            typeof state.watchdogShipsKpiByOwner[i].sla != "string"
              ? state.watchdogShipsKpiByOwner[i].sla
              : undefined;
          state.watchdogShipsKpiByOwner[i].kpi_visibility =
            state.watchdogShipsKpiByOwner[i].sla_visibility ?? false;
        } else if (state.kpiResultsType === "nosla") {
          state.watchdogShipsKpiByOwner[i].kpi =
            typeof state.watchdogShipsKpiByOwner[i].nosla != "string"
              ? state.watchdogShipsKpiByOwner[i].nosla
              : undefined;
          state.watchdogShipsKpiByOwner[i].kpi_visibility =
            state.watchdogShipsKpiByOwner[i].nosla_visibility ?? false;
        } else {
          state.watchdogShipsKpiByOwner[i].kpi =
            typeof state.watchdogShipsKpiByOwner[i].total != "string"
              ? state.watchdogShipsKpiByOwner[i].total
              : undefined;
          state.watchdogShipsKpiByOwner[i].kpi_visibility =
            state.watchdogShipsKpiByOwner[i].total_visibility ?? false;
        }
        for (let j = 0; j < state.watchdogShipsKpiByOwner[i].ships.length; j++) {
          if (state.kpiResultsType === "sla") {
            state.watchdogShipsKpiByOwner[i].ships[j].kpi =
              typeof state.watchdogShipsKpiByOwner[i].ships[j].sla != "string"
                ? state.watchdogShipsKpiByOwner[i].ships[j].sla
                : undefined;
            state.watchdogShipsKpiByOwner[i].ships[j].kpi_visibility =
              state.watchdogShipsKpiByOwner[i].ships[j].sla_visibility ?? false;
          } else if (state.kpiResultsType === "nosla") {
            state.watchdogShipsKpiByOwner[i].ships[j].kpi =
              typeof state.watchdogShipsKpiByOwner[i].ships[j].nosla != "string"
                ? state.watchdogShipsKpiByOwner[i].ships[j].nosla
                : undefined;
            state.watchdogShipsKpiByOwner[i].ships[j].kpi_visibility =
              state.watchdogShipsKpiByOwner[i].ships[j].nosla_visibility ?? false;
          } else {
            state.watchdogShipsKpiByOwner[i].ships[j].kpi =
              typeof state.watchdogShipsKpiByOwner[i].ships[j].total != "string"
                ? state.watchdogShipsKpiByOwner[i].ships[j].total
                : undefined;
            state.watchdogShipsKpiByOwner[i].ships[j].kpi_visibility =
              state.watchdogShipsKpiByOwner[i].ships[j].total_visibility ?? false;
          }
        }
      }
    },
    setResponseTimeKPIType(state) {
      for (let i = 0; i < state.responseKpiData.length; i++) {
        if (state.kpiResultsType === "sla") {
          state.responseKpiData[i].kpi =
            typeof state.responseKpiData[i].sla != "string"
              ? state.responseKpiData[i].sla
              : undefined;
          state.responseKpiData[i].kpi_visibility =
            state.responseKpiData[i].sla_visibility ?? false;
        } else if (state.kpiResultsType === "nosla") {
          state.responseKpiData[i].kpi =
            typeof state.responseKpiData[i].nosla != "string"
              ? state.responseKpiData[i].nosla
              : undefined;
          state.responseKpiData[i].kpi_visibility =
            state.responseKpiData[i].nosla_visibility ?? false;
        } else {
          state.responseKpiData[i].kpi =
            typeof state.responseKpiData[i].total != "string"
              ? state.responseKpiData[i].total
              : undefined;
          state.responseKpiData[i].kpi_visibility =
            state.responseKpiData[i].total_visibility ?? false;
        }
        for (let j = 0; j < state.responseKpiData[i].ships.length; j++) {
          if (state.kpiResultsType === "sla") {
            state.responseKpiData[i].ships[j].kpi =
              typeof state.responseKpiData[i].ships[j].sla != "string"
                ? state.responseKpiData[i].ships[j].sla
                : undefined;
            state.responseKpiData[i].ships[j].kpi_visibility =
              state.responseKpiData[i].ships[j].sla_visibility ?? false;
          } else if (state.kpiResultsType === "nosla") {
            state.responseKpiData[i].ships[j].kpi =
              typeof state.responseKpiData[i].ships[j].nosla != "string"
                ? state.responseKpiData[i].ships[j].nosla
                : undefined;
            state.responseKpiData[i].ships[j].kpi_visibility =
              state.responseKpiData[i].ships[j].nosla_visibility ?? false;
          } else {
            state.responseKpiData[i].ships[j].kpi =
              typeof state.responseKpiData[i].ships[j].total != "string"
                ? state.responseKpiData[i].ships[j].total
                : undefined;
            state.responseKpiData[i].ships[j].kpi_visibility =
              state.responseKpiData[i].ships[j].total_visibility ?? false;
          }
        }
      }
    },

    setActivationTimeKpiType(state) {
      state.activationTimeKpiData
      for (let i = 0; i < state.activationTimeKpiData.length; i++) {
        if (state.kpiResultsType === "sla") {
          state.activationTimeKpiData[i].kpi =
            typeof state.activationTimeKpiData[i].sla != "string"
              ? state.activationTimeKpiData[i].sla
              : undefined;
          state.activationTimeKpiData[i].kpi_visibility =
            state.activationTimeKpiData[i].sla_visibility ?? false;
        } else if (state.kpiResultsType === "nosla") {
          state.activationTimeKpiData[i].kpi =
            typeof state.activationTimeKpiData[i].nosla != "string"
              ? state.activationTimeKpiData[i].nosla
              : undefined;
          state.activationTimeKpiData[i].kpi_visibility =
            state.activationTimeKpiData[i].nosla_visibility ?? false;
        } else {
          state.activationTimeKpiData[i].kpi =
            typeof state.activationTimeKpiData[i].total != "string"
              ? state.activationTimeKpiData[i].total
              : undefined;
          state.activationTimeKpiData[i].kpi_visibility =
            state.activationTimeKpiData[i].total_visibility ?? false;
        }
        for (let j = 0; j < state.activationTimeKpiData[i].ships.length; j++) {
          if (state.kpiResultsType === "sla") {
            state.activationTimeKpiData[i].ships[j].kpi =
              typeof state.activationTimeKpiData[i].ships[j].sla != "string"
                ? state.activationTimeKpiData[i].ships[j].sla
                : undefined;
            state.activationTimeKpiData[i].ships[j].kpi_visibility =
              state.activationTimeKpiData[i].ships[j].sla_visibility ?? false;
          } else if (state.kpiResultsType === "nosla") {
            state.activationTimeKpiData[i].ships[j].kpi =
              typeof state.activationTimeKpiData[i].ships[j].nosla != "string"
                ? state.activationTimeKpiData[i].ships[j].nosla
                : undefined;
            state.activationTimeKpiData[i].ships[j].kpi_visibility =
              state.activationTimeKpiData[i].ships[j].nosla_visibility ?? false;
          } else {
            state.activationTimeKpiData[i].ships[j].kpi =
              typeof state.activationTimeKpiData[i].ships[j].total != "string"
                ? state.activationTimeKpiData[i].ships[j].total
                : undefined;
            state.activationTimeKpiData[i].ships[j].kpi_visibility =
              state.activationTimeKpiData[i].ships[j].total_visibility ?? false;
          }
        }
      }
    },

    getResponseTimeKpiSuccess(state, responseTimeKpiParams) {
      state.responseKpiData = responseTimeKpiParams.data.kpis;
      state.selectedKpiDisplayName =
        responseTimeKpiParams.data.kpi_name ?? "DETAILS";
    },
    getResponseTimeKpiFailure(state) {
      state.responseKpiData = [];
      if (
        state.selectedKpiDisplayName === undefined ||
        state.selectedKpiDisplayName === null ||
        state.selectedKpiDisplayName === ""
      ) {
        state.selectedKpiDisplayName = "DETAILS";
      }
    },

    getYearKPIPDFSuccess(state, yearKPIPDFParams) {
      state.yearKPIPDFData = yearKPIPDFParams.data.kpis;
    },
    getYearKPIPDFFailure(state) {
      state.yearKPIPDFData = [];
    },

    getImporterKpiSuccess(state, importerKpiParams) {
      state.importerKpiData = importerKpiParams.data.kpis;
      state.selectedKpiDisplayName =
        importerKpiParams.data.kpi_name ?? "DETAILS";
    },
    getImporterKpiFailure(state) {
      state.importerKpiData = [];
      if (
        state.selectedKpiDisplayName === undefined ||
        state.selectedKpiDisplayName === null ||
        state.selectedKpiDisplayName === ""
      ) {
        state.selectedKpiDisplayName = "DETAILS";
      }
    },

    getActivationTimeKpiSuccess(state, activationKpiParams) {
      state.activationTimeKpiData = activationKpiParams.data.kpis;
      state.selectedKpiDisplayName =
        activationKpiParams.data.kpi_name ?? "DETAILS";
    },
    getActivationTimeKpiFailure(state) {
      state.activationTimeKpiData = [];
      if (
        state.selectedKpiDisplayName === undefined ||
        state.selectedKpiDisplayName === null ||
        state.selectedKpiDisplayName === ""
      ) {
        state.selectedKpiDisplayName = "DETAILS";
      }
    },

    getWatchdogShipsKpiByOwnerSuccess(state, watchdogKpiParams) {
      state.watchdogShipsKpiByOwner = watchdogKpiParams.data.kpis;
      state.selectedKpiDisplayName = watchdogKpiParams.data.kpi_name;
    },

    getWatchdogShipsKpiByOwnerFailure(state) {
      state.watchdogShipsKpiByOwner = [];
      if (
        state.selectedKpiDisplayName === undefined ||
        state.selectedKpiDisplayName === null ||
        state.selectedKpiDisplayName === ""
      ) {
        state.selectedKpiDisplayName = "DETAILS";
      }
    },

    getWatchdogShipKpiDataSuccess(state, watchdogKpiShipParams) {
      state.watchdogShipKpiData = watchdogKpiShipParams.data.kpis;
      state.selectedKpiDisplayName =
        watchdogKpiShipParams.data.kpi_name ?? "DETAILS";
      state.watchdogShipServiceStatuses = watchdogKpiShipParams.data.statuses;
    },

    getWatchdogShipKpiDataFailure(state) {
      state.watchdogShipKpiData = [];
      state.watchdogShipServiceStatuses = [];
      if (
        state.selectedKpiDisplayName === undefined ||
        state.selectedKpiDisplayName === null ||
        state.selectedKpiDisplayName === ""
      ) {
        state.selectedKpiDisplayName = "DETAILS";
      }
    },

    getWatchdogKpiExclusionsSuccess(state, watchdogKpiExclusions) {
      state.watchdogKpiExcludions = watchdogKpiExclusions.data.exclusions;
    },

    getWatchdogKpiExclusionsFailure(state) {
      state.watchdogKpiExcludions = [];
    },

    setMaintainer(state, maintainer) {
      state.selectedMaintainer = maintainer;
    },

    clearKpiName(state) {
      state.selectedKpi = "";
      state.selectedKpiDispalyName = "DETAILS";
    },
    selectKpiDisplayName(state, displayName) {
      state.selectedKpiDisplayName = displayName;
    },

    updateDate(state, date) {
      state.dateBetween.dateFrom = date.dateFrom;
      state.dateBetween.dateTo = date.dateTo;
    },

    updateKpiErrorMsg(state, errorMsg) {
      state.kpiErrorMsg = errorMsg;
    },

    updateExclusionStatus(state, params) {
      if (params) {
        let stat = {
          title: params.title,
          message: params.msg,
          type: params.type
        };
        state.kpiExclusionModificationStatus = stat;
      } else {
        state.kpiExclusionModificationStatus = {};
      }
    },
    updateToastData(state, data) {
      if (data) {
        let stat = {
          title: data.title,
          message: data.msg,
          type: data.type
        };
        state.toastData = stat;
      } else {
        state.toastData = {};
      }
    },
    setDataIsLoading(state, value) {
      state.dataIsLoading = value;
    }
  },
  getters: {
    kpiData: (state) => {
      return state.kpiData;
    },
    kpiAlarms: (state) => {
      return state.evtKpiAlarms;
    },

    isDashboard: (state) => {
      return state.isDashboard;
    },

    dateBetween: (state) => {
      return state.dateBetween;
    },
    dateBetweenReal: (state) => {
      return state.dateBetweenReal;
    },
    selectedKpi: (state) => {
      return state.selectedKpi;
    },
    selectedKpiDisplayName: (state) => {
      return state.selectedKpiDisplayName;
    },
    selectedMaintainer: (state) => {
      return state.selectedMaintainer;
    },
    responseTimeKpiData: (state) => {
      return state.responseKpiData;
    },
    importerKpiData: (state) => {
      return state.importerKpiData;
    },
    yearKPIPDFData: (state) => {
      return state.yearKPIPDFData;
    },
    activationTimeKpiData: (state) => {
      return state.activationTimeKpiData;
    },
    watchdogKpiData: (state) => {
      return state.watchdogKpiData;
    },
    watchdogShipsKpiByOwner: (state) => {
      return state.watchdogShipsKpiByOwner;
    },
    watchdogShipKpi: (state) => {
      return state.watchdogShipKpiData;
    },
    watchdogShipServiceStatuses: (state) => {
      return state.watchdogShipServiceStatuses;
    },
    watchdogKpiExcludions: (state) => {
      return state.watchdogKpiExcludions;
    },

    errorMessage: (state) => {
      return state.kpiErrorMsg;
    },
    exclusionStatus: (state) => {
      return state.kpiExclusionModificationStatus;
    },
    toastData: (state) => {
      return state.toastData;
    },
    dashboardList: (state) => {
      return state.dashboardList;
    },
    kpiResultsType: (state) => {
      return state.kpiResultsType;
    },
    dataIsLoading: (state) => {
      return state.dataIsLoading;
    },
    serverKpiList: (state) => {
      return state.serverList;
    }
  }
};
