import { createRouter, createWebHistory } from "vue-router";
import Auth from "../views/EmcAuth.vue";
import { sessionEndLogout, sessionCheck, getUser } from "@/router/utils";

const RdmUsers = () => import("../views/RdmUsers.vue");
const RdmUsersList = () => import("../components/rdm/RdmUsersList.vue");
const RdmAddUser = () => import("../components/rdm/RdmAddUser.vue");
const RdmEditUser = () => import("../components/rdm/RdmEditUser.vue");
const RdmEditUserShips = () => import("../components/rdm/RdmEditUserShips");

const EmcConsole = () => import("../views/EmcConsole.vue");
const EmcUsersList = () => import("../components/emcConsole/EmcUsersList.vue");
const EmcAddUser = () => import("../components/emcConsole/EmcAddUser.vue");
const EmcEditUser = () => import("../components/emcConsole/EmcEditUser.vue");
const EmcLogView = () => import("../components/emcConsole/EmcLogView.vue");

const Kpi = () => import("../views/EmcKpi.vue");
const KpiDashboard = () => import("../components/kpi/KpiDashboard.vue");
const KpiDetails = () => import("../components/kpi/KpiDetails.vue");
const ImporterKpi = () => import("../components/kpi/ImporterKpi.vue");
const WatchdogShipKpi = () => import("../components/kpi/WatchdogShipKpi.vue");

const Ship = () => import("../views/EmcShip.vue");
const ShipList = () => import("../components/ship/ShipList.vue");
const ShipProfile = () => import("../components/ship/ShipProfile.vue");
const AddNew = () => import("../components/ship/AddNew.vue");

const ResponseTime = () => import("../views/ResponseTime.vue");
const TicketsList = () => import("../components/Tickets/TicketsList.vue");
const TicketsClosedList = () => import("../components/Tickets/TicketsClosedList");
const TicketsCustomList = () => import("../components/Tickets/TicketsCustomList.vue");

const ActivationTime = () => import("../views/ActivationTime.vue");
const EmailsTable = () => import("../components/activation/EmailsList.vue");
const DeletedEmailsTable = () => import("../components/activation/DeletedEmailsList.vue");
const EmailsListFilters = () => import ("../components/activation/EmailsListFilters.vue");

const ApiUsers = () => import ("../views/ApiUsers.vue")
const ApiUsersTable = () => import ("../components/tables/ApiUsersTable.vue")
const UserPermissionsData = () => import ("../components/permissions/UserPermissionsData.vue");

const EvtApiLicence = () => import("../views/EvtApiLicence.vue");
const EvtUsersList = () =>
  import("../components/evtApiLicence/EvtUsersList.vue");
const EvtAddUser = () => import("../components/evtApiLicence/EvtAddUser.vue");

const routes = [
  { path: "/:pathMatch(.*)*", component: Auth },
  {
    path: "/login",
    alias: "/",
    name: "login",
    component: Auth,
  },
  {
    path: "/rdmUsers",
    name: "RdmUsers",
    component: RdmUsers,
    children: [
      {
        path: "/rdmUsers/listUsers",
        component: RdmUsersList,
      },
      {
        path: "/rdmUsers/addUser",
        component: RdmAddUser,
        props: false,
      },
      {
        path: `/rdmUsers/editUserForm`,
        component: RdmEditUser,
        props: (route) => ({ query: route.query.id }),
      },
      {
        path: `/rdmUsers/editUserShipsForm`,
        component: RdmEditUserShips,
        props: (route) => ({ query: route.query.id }),
      },
    ],
  },
  {
    path: "/emcConsole",
    name: "EMCConsole",
    component: EmcConsole,
    children: [
      {
        path: "/emcConsole/listUsers",
        component: EmcUsersList,
      },
      {
        path: "/emcConsole/addUser",
        component: EmcAddUser,
      },
      {
        path: "/emcConsole/logView",
        name: "emcLogView",
        component: EmcLogView,
        // props: (route) => ({ query: route.query.id }),
        props: true,
      },
      {
        path: `/emcConsole/editUserForm`,
        component: EmcEditUser,
        props: (route) => ({ query: route.query.id }),
      },
    ],
  },
  {
    path: "/ship",
    name: "Ship",
    component: Ship,
    children: [
      {
        path: "/ship/listShips",
        component: ShipList,
      },
      {
        name: "shipProfile",
        path: "/ship/profile",
        component: ShipProfile,
        props: true,
      },
      {
        name: "addNew",
        path: "/ship/profile/addNew",
        component: AddNew,
        props: true,
      },
    ],
  },
  {
    path: "/evtApiLicence",
    name: "EVTApiLicence",
    component: EvtApiLicence,
    children: [
      {
        path: "/evtApiLicence/listUsers",
        component: EvtUsersList,
      },
      {
        path: "/evtApiLicence/addUser",
        component: EvtAddUser,
      },
    ],
  },
  {
    path: "/kpi",
    name: "Kpi",
    component: Kpi,
    children: [
      {
        name: "kpiDashboard",
        path: "/kpi/kpiDashboard",
        component: KpiDashboard,
      },
      {
        path: "/kpi/importerKpi",
        component: ImporterKpi,
      },
      {
        name: "KpiDetails",
        path: "/kpi/kpiDetails",
        component: KpiDetails,
      },
      {
        name: "watchdogShipKpi",
        path: "/kpi/watchdogShipKpi",
        component: WatchdogShipKpi,
        props: true,
      },
    ],
  },
  {
    path: "/tickets",
    name: "Tickets",
    component: ResponseTime,
    children: [
      {
        name: "ticketsList",
        path: "/tickets/listTickets",
        component: TicketsList,
      },
      {
        name: "ticketsClosedList",
        path: "/tickets/listClosedTickets",
        component: TicketsClosedList,
      },
      {
        name: "ticketsCustomList",
        path: "/tickets/listCustomTickets",
        component: TicketsCustomList,
      },
    ],
  },
  {
    path: "/activationTime",
    name: "DataEmails",
    component: ActivationTime,
    children: [
      {
        name: "emailsList",
        path: "/activationTime/listEmails",
        component: EmailsTable,
      },
      {
        name: "deletedEmailsList",
        path: "/activationTime/listDeletedEmails",
        component: DeletedEmailsTable,
      },
      {
        name: "emailsListFilters",
        path: "/activationTime/emailsListFilters",
        component: EmailsListFilters,
      },
    ],
  },
  {
    path: "/apiUsers",
    name: "apiUsers",
    component: ApiUsers,
    children: [
      {
        name: "/apiUsersList",
        path: "/apiUsers/apiUsersList",
        component: ApiUsersTable,
      },
      {
        name: "UserPermissionsData",
        path: "/apiUsers/permissions",
        component: UserPermissionsData,
      },
    ]
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const loggedIn = getUser();

  if (loggedIn != null) {
    let session = await sessionCheck(loggedIn);
    await sessionEndLogout(session, next);
  }

  if ((to.name !== "login" && !loggedIn) || (to.name === "" && !loggedIn))
    next({ name: "login" });
  else next();
});

export default router;
