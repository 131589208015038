import store from "../index";
import TicketsService from "../../services/tickets.service";
import ShipsService from "../../services/ships.service";
import EmcService from "../../services/emc.service";

const ticketsInitializeState = {
  tickets: null,
  owners: null,
  ships: null,
  users: null,
  uuid: null,
  filtTickets: null,
  ticket_propositions: null,
  tickets_last_update: null,

  toastData: {
    message: "",
    title: "",
    type: "",
  },
};

export const tickets = {
  namespaced: true,
  state: ticketsInitializeState,
  actions: {
    getTickets({ commit }, data) {
      return TicketsService.getTickets(data)
        .then(
          (result) => {
            commit("getTicketsSuccess", result.data.tickets);
            return Promise.resolve(result);
          },
          (error) => {
            commit("getTicketsFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateToastData", {
            title: "Error!",
            msg: errorMsg,
            type: "error",
          });
        });
    },
    setFilteredTickets({ commit }, filtTickets) {
      commit("setFilteredTickets", filtTickets);
    },
    getOwners({ commit }, maintainer) {
      return ShipsService.getOwnersV2(maintainer)
        .then(
          (result) => {
            commit("getOwnersSuccess", result.data.owners);
            return Promise.resolve(result);
          },
          (error) => {
            commit("getOwnersFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateToastData", {
            title: "Error!",
            msg: errorMsg,
            type: "error",
          });
        });
    },
    getOwnerShips({ commit }, data) {
      return ShipsService.getOwnerShipsV2(data.maintainer, data.ownerId)
        .then(
          (result) => {
            commit("getOwnerShipsSuccess", result.data.ship);
            return Promise.resolve(result);
          },
          (error) => {
            commit("getOwnerShipsFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateToastData", {
            title: "Error!",
            msg: errorMsg,
            type: "error",
          });
        });
    },
    getUsers({ commit }) {
      return EmcService.getEmcAllUsers()
        .then(
          (result) => {
            commit("getUsersSuccess", result.data);
            return Promise.resolve(result);
          },
          (error) => {
            commit("getUsersFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateToastData", {
            title: "Error!",
            msg: errorMsg,
            type: "error",
          });
        });
    },
    updateTicket({ commit }, data) {
      return TicketsService.updateTicket(data)
        .then(
          (result) => {
            commit("updateToastData", {
              title: "Success!",
              msg: "Ticket update success",
              type: "success",
            });
            return Promise.resolve(result);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateToastData", {
            title: "Error!",
            msg: errorMsg,
            type: "error",
          });
        });
    },

    getTicketsforShip({ commit }, data) {
      return TicketsService.getTicketsforShip(data)
        .then(
          (result) => {
            commit("getTicketsSuccess", []);
            return Promise.resolve(result);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("setTicketsError", {
            title: "Error!",
            msg: errorMsg,
            type: "error",
          });
        });
    },

    mergeTickets({ commit }, data) {
      return TicketsService.mergeTickets(data)
        .then(
          (result) => {
            commit("updateToastData", {
              title: "Success!",
              msg: "Ticket merge success",
              type: "success",
            });
            return Promise.resolve(result);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateToastData", {
            title: "Error!",
            msg: errorMsg,
            type: "error",
          });
        });
    },
    getUuid({ commit }) {
      return TicketsService.getUuid()
        .then(
          (result) => {
            commit("getUuidSuccess", result.data.id);
            return Promise.resolve(result);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateToastData", {
            title: "Error!",
            msg: errorMsg,
            type: "error",
          });
        });
    },
    createTicket({ commit }, ticket) {
      return TicketsService.createTicket(ticket)
        .then(
          (result) => {
            commit("updateToastData", {
              title: "Success!",
              msg: "Ticket create success",
              type: "success",
            });
            return Promise.resolve(result);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateToastData", {
            title: "Error!",
            msg: errorMsg,
            type: "error",
          });
        });
    },
    closeTicket({ commit }, data) {
      return TicketsService.closeTicket(data)
        .then(
          (result) => {
            commit("updateToastData", {
              title: "Success!",
              msg: "Ticket close success",
              type: "success",
            });
            return Promise.resolve(result);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateToastData", {
            title: "Error!",
            msg: errorMsg,
            type: "error",
          });
        });
    },
    deleteTicket({ commit }, data) {
      return TicketsService.deleteTicket(data)
        .then(
          (result) => {
            commit("updateToastData", {
              title: "Success!",
              msg: "Ticket delete success",
              type: "success",
            });
            return Promise.resolve(result);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateToastData", {
            title: "Error!",
            msg: errorMsg,
            type: "error",
          });
        });
    },
    clearShips({ commit }) {
      commit("clearShips");
    },
    getTicketPropositions({ commit }) {
      return TicketsService.getTicketPropositions()
        .then(
          (result) => {
            commit(
              "getTicketPropositionsSuccess",
              result.data.ticket_propositions
            );
            return Promise.resolve(result);
          },
          (error) => {
            commit("getTicketPropositionsFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateToastData", {
            title: "Error!",
            msg: errorMsg,
            type: "error",
          });
          commit("getTicketPropositionsFailure");
          return Promise.reject(error);
        });
    },
    createTicketFromProposition({ commit }, id) {
      return TicketsService.createTicketFromPropositions(id)
        .then(
          (result) => {
            commit("updateToastData", {
              title: "Success!",
              msg: result.data.message,
              type: "success",
            });
            return Promise.resolve(result);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateToastData", {
            title: "Error!",
            msg: errorMsg,
            type: "error",
          });
          return Promise.reject(error);
        });
    },
    mergePropositionWithTicket({ commit }, data) {
      return TicketsService.mergePropositionWithTicket(data)
        .then(
          (result) => {
            commit("updateToastData", {
              title: "Success!",
              msg: result.data.message,
              type: "success",
            });
            return Promise.resolve(result);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateToastData", {
            title: "Error!",
            msg: errorMsg,
            type: "error",
          });
          return Promise.reject(error);
        });
    },
    removeProposition({ commit }, data) {
      return TicketsService.removeProposition(data)
        .then(
          (result) => {
            commit("updateToastData", {
              title: "Success!",
              msg: result.data.message,
              type: "success",
            });
            return Promise.resolve(result);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateToastData", {
            title: "Error!",
            msg: errorMsg,
            type: "error",
          });
          return Promise.reject(error);
        });
    },
    getTicketsLastUpdate({ commit }) {
      return TicketsService.getTicketsLastUpdate()
        .then(
          (result) => {
            commit(
              "getTicketsLastUpdateSuccess",
              result.data.last_update
            );
            return Promise.resolve(result);
          },
          (error) => {
            commit("getTicketsLastUpdateFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateToastData", {
            title: "Error!",
            msg: errorMsg,
            type: "error",
          });
          commit("getTicketPropositionsFailure");
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    getTicketsSuccess(state, tickets) {
      state.tickets = tickets;
    },
    setFilteredTickets(state, data) {
      state.filtTickets = data;
    },
    getTicketsFailure(state, tickets) {
      state.tickets = null;
    },

    getOwnersSuccess(state, owners) {
      let emptyField = {
        owner_name: "--",
        owner_id: null,
      };
      owners.unshift(emptyField);
      state.owners = owners;
    },
    getOwnersFailure(state) {
      state.owners = null;
    },

    getOwnerShipsSuccess(state, ships) {
      let emptyField = {
        ship_name: "--",
        ship_id: null,
      };
      ships.unshift(emptyField);
      state.ships = ships;
    },
    getOwnerShipsFailure(state) {
      state.ships = null;
    },

    getUsersSuccess(state, users) {
      let emptyField = {
        email: "--",
        user_id: null,
      };
      users.unshift(emptyField);
      state.users = users;
    },
    getUsersFailure(state) {
      state.users = null;
    },

    getUuidSuccess(state, uuid) {
      state.uuid = uuid;
    },

    updateToastData(state, data) {
      if (data) {
        let stat = {
          title: data.title,
          message: data.msg,
          type: data.type,
        };
        state.toastData = stat;
      } else {
        state.toastData = {};
      }
    },
    clearShips(state) {
      state.ships = [];
    },
    getTicketPropositionsSuccess(state, ticket_propositions) {
      state.ticket_propositions = ticket_propositions;
    },
    getTicketPropositionsFailure(state) {
      state.tickets_propositions = null;
    },
    getTicketsLastUpdateSuccess(state, tickets_last_update) {
      state.tickets_last_update = tickets_last_update;
    },
    getTicketsLastUpdateFailure(state) {
      state.tickets_last_update = null;
    },
  },
  getters: {
    tickets: (state) => {
      return state.tickets;
    },
    getFilteredTickets: (state) => {
      return state.filtTickets;
    },
    owners: (state) => {
      return state.owners;
    },
    ships: (state) => {
      return state.ships;
    },
    users: (state) => {
      return state.users;
    },
    uuid: (state) => {
      return state.uuid;
    },
    toastData: (state) => {
      return state.toastData;
    },
    ticketsPropositions: (state) => {
      return state.ticket_propositions;
    },
    ticketsLastUpdate: (state) => {
      return state.tickets_last_update;
    },
  },
};
