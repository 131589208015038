<template>
  <div class="edit-exclusion-form">
    <span class="p-label">Start time (UTC)</span>
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <font-awesome-icon :icon="['fas', 'calendar-times']" />
      </span>
      <Calendar
        v-model="state.dateFrom"
        :dateFormat="dayjs(state.dateFrom).format('YYYY-MM-DD HH:mm:00')"
        :key="state.dateFrom"
        :showTime="false"
        :showSeconds="false"
        :manualInput="false"
        @date-select="updateDate('dateFrom')"
        placeholder="Select start exclusion date"
      >
        <template #footer>
          <div class="p-datepicker-buttonbar">
            <Button
              class="p-button p-component p-button-text"
              @click="setTodayDate('dateFrom')"
            >
              Today
            </Button>
            <Button
              class="p-button p-component p-button-text"
              @click="clearDate('dateFrom')"
            >
              Clear
            </Button>
          </div>
          <div class="p-timepicker">
            <TimePicker
              :defaultValue="state.dateFrom ? state.dateFrom.getHours() : 0"
              :minValue="0"
              :maxValue="23"
              :maxLength="2"
              :stepUp="1"
              :stepDn="1"
              :isSeparator="true"
              @changedTime="updateTime($event, 'hour', 'dateFrom')"
            />
            <TimePicker
              :defaultValue="state.dateFrom ? state.dateFrom.getMinutes() : 0"
              :minValue="0"
              :maxValue="59"
              :maxLength="2"
              :stepUp="1"
              :stepDn="1"
              :isSeparator="false"
              @changedTime="updateTime($event, 'minutes', 'dateFrom')"
            />
          </div>
        </template>
      </Calendar>
    </div>

    <span class="p-error" v-if="validation.startTimeIsNull">
      The start date is required.
    </span>
    <span class="p-error" v-if="validation.startTimeIsInUse">
      The start date is allready included in other exclusion.
    </span>

    <span class="p-label">End date (UTC)</span>
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <font-awesome-icon :icon="['fas', 'calendar-times']" />
      </span>
      <Calendar
        v-model="state.dateTo"
        :dateFormat="dayjs(state.dateTo).format('YYYY-MM-DD HH:mm:00')"
        :key="state.dateTo"
        :showTime="false"
        :showSeconds="false"
        :manualInput="false"
        @date-select="updateDate('dateTo')"
        placeholder="Select end exclusion date"
      >
        <template #footer>
          <div class="p-datepicker-buttonbar">
            <Button
              class="p-button p-component p-button-text"
              @click="setTodayDate('dateTo')"
            >
              Today
            </Button>
            <Button
              class="p-button p-component p-button-text"
              @click="clearDate('dateTo')"
            >
              Clear
            </Button>
          </div>
          <div class="p-timepicker">
            <TimePicker
              :defaultValue="state.dateTo ? state.dateTo.getHours() : 0"
              :minValue="0"
              :maxValue="23"
              :maxLength="2"
              :stepUp="1"
              :stepDn="1"
              :isSeparator="true"
              @changedTime="updateTime($event, 'hour', 'dateTo')"
            />
            <TimePicker
              :defaultValue="state.dateTo ? state.dateTo.getMinutes() : 0"
              :minValue="0"
              :maxValue="59"
              :maxLength="2"
              :stepUp="1"
              :stepDn="1"
              :isSeparator="false"
              @changedTime="updateTime($event, 'minutes', 'dateTo')"
            />
          </div>
        </template>
      </Calendar>
    </div>

    <span class="p-error" v-if="validation.endTimeIsInUse">
      The end date is allready included in other exclusion.
    </span>
    <span class="p-error" v-if="validation.endTimeGreaterThenStartTime">
      End time must be greater then start time.
    </span>

    <span class="p-label">Description</span>
    <textarea
      v-model="state.description"
      @input="updateDescription()"
    ></textarea>
  </div>
</template>

<script>
import { onMounted, getCurrentInstance, reactive, ref, watch, } from "vue";
import dayjs from "dayjs";
import { useStore } from "vuex";
import TimePicker from "../commons/TimePicker";
import { useRoute } from "vue-router";




export default {
  name: "EditWatchdogKpiExclusionForm",
  props: {
    state_: null,
    exclusions: null,
    showDeleteButton: null,
    showCloseBtn: null,
  },
  components: { TimePicker },
  emits: ['hideNewExclusionForm'],

  async setup(props, { emit }) {

    const instance = getCurrentInstance();
    const store = useStore();
    const route = useRoute();
    const closeLoading = ref(false);
    const deleteLoading = ref(false);


    const state = reactive({
      id: null,
      description: null,
      tickets: null,
      dateFrom: null,
      dateTo: null
    });

    const validation = reactive({
      startTimeIsNull: false,
      startTimeIsInUse: false,
      endTimeIsInUse: false,
      endTimeGreaterThenStartTime: false
    })

    

    const hideNewExclusionForm = () => {
      emit("hideNewExclusionForm");
    }

    watch(state, () => {
      validation.startTimeIsNull = state.dateFrom == null;
    })

    //DO OSOBNEGO KOMPONENTU !!!!!
    const convertDateToUTC = (date) => {
      return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      );
    };

    const dateFromEdit = reactive({
      hours: state.dateFrom && state.dateFrom.getHours(),
      minutes: state.dateFrom && state.dateFrom.getMinutes(),
      seconds: 0,
    });

    const dateToEdit = reactive({
      hours: state.dateTo && state.dateTo.getHours(),
      minutes: state.dateTo && state.dateTo.getMinutes(),
      seconds: 0,
    });

    const updateTime = (event, timeType, dateType) => {
      if (timeType === "hour" && dateType === "dateFrom") {
        dateFromEdit.hours = event;
        updateDate("dateFrom");
      }
      if (timeType === "minutes" && dateType === "dateFrom") {
        dateFromEdit.minutes = event;
        updateDate("dateFrom");
      }
      if (timeType === "hour" && dateType === "dateTo") {
        dateToEdit.hours = event;
        updateDate("dateTo");
      }
      if (timeType === "minutes" && dateType === "dateTo") {
        dateToEdit.minutes = event;
        updateDate("dateTo");
      }
    };

    //Metoda do sprawdzania, czy wyłączenia się na siebie nie nakładają - wymaganie wycofane !!

    // const checkIfDateIsNotInUse = (time, dateType) => {
    //   time = dayjs(time).format('YYYY-MM-DD HH:mm:00');
    //
    //   for(let exclusion of props.exclusions){
    //     if(time >= exclusion.start_time && time < exclusion.end_time){
    //       if(dateType === "dateFrom"){
    //         validation.startTimeIsInUse = true;
    //       }
    //       else if(dateType === "dateTo"){
    //         validation.endTimeIsInUse = true;
    //       }
    //       return;
    //     }
    //     else{
    //       if(dateType === "dateFrom"){
    //         validation.startTimeIsInUse = false;
    //       }
    //       else if(dateType === "dateTo"){
    //         validation.endTimeIsInUse = false;
    //       }
    //     }
    //   }
    //
    //   return true;
    // }

    const checkIfEndTimeIsValid = () => {
      validation.endTimeGreaterThenStartTime = state.dateTo < state.dateFrom
    }

    const updateDate = async (dateType) => {


      if (dateType === "dateFrom") {
        state.dateFrom.setHours(dateFromEdit.hours);
        state.dateFrom.setMinutes(dateFromEdit.minutes);
        state.dateFrom.setSeconds(dateFromEdit.seconds);

        //checkIfDateIsNotInUse(state.dateFrom, "dateFrom");
      }
      if (dateType === "dateTo") {
        state.dateTo.setHours(dateToEdit.hours);
        state.dateTo.setMinutes(dateToEdit.minutes);
        state.dateTo.setSeconds(dateToEdit.seconds);



        //checkIfDateIsNotInUse(state.dateTo, "dateTo");
        checkIfEndTimeIsValid();
      }

      await saveExForm();

      instance.proxy.$forceUpdate();
    };

    onMounted(() => {
      if (route.query.exclusion_id) {
      let dataSave = store.getters["ships/getExclusionData"];

      state.dateFrom = dayjs(dataSave.dateFrom).toDate();
      state.dateTo = dayjs(dataSave.dateTo).toDate();
      state.id = dataSave.id;
      state.description = dataSave.description;
      }
    })

    const updateDescription = () => {
      saveExForm()
    }


    const saveExForm = () => {
      const param ={
        dateTo: dayjs(state.dateTo).format('YYYY-MM-DD HH:mm:00'),
        dateFrom: dayjs(state.dateFrom).format('YYYY-MM-DD HH:mm:00'),
        description:state.description,
        id: state.id
      }
        store.dispatch("ships/setExclusionData", param)
    }


    const setTodayDate = (dateType) => {
      state[dateType] = convertDateToUTC(dayjs().toDate());

      if (dateType === "dateFrom") {
        dateFromEdit.hours = 0;
        dateFromEdit.minutes = 0;
        dateFromEdit.seconds = 0;
      }
      else if (dateType === "dateTo") {
        dateToEdit.hours = state[dateType].getHours();
        dateToEdit.minutes = state[dateType].getMinutes();
        dateToEdit.seconds = state[dateType].getSeconds();
      }

      updateDate(dateType);
    }

    const clearDate = (dateType) => {
      state[dateType] = null;
      saveExForm();
    }
    //.........................................

    return {
      state,
      deleteLoading,
      closeLoading,
      validation,
      dayjs,
      updateDescription,
      hideNewExclusionForm,
      updateDate,
      updateTime,
      setTodayDate,
      clearDate,
    }

  }
}
</script>

<style scoped>
.edit-exclusion-form{
  width: calc(50% - 60px);
  height: 316px;
  box-sizing: border-box;
  margin: 20px;
  padding: 8px 8px 8px ;
  -webkit-box-shadow: 0 0 17px -7px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0 0 17px -7px rgba(0, 0, 0, 1);
  box-shadow: 0 0 17px -7px rgba(0, 0, 0, 1);
  border-radius: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.edit-exclusion-form  .p-label {
  margin: 10px 10px 10px 0;
}
</style>
