import axios from "axios";
import authHeader from "./auth-header";

class ShipsService {
  getMaintainersV2() {
    return axios.get(
      `${
        process.env.VUE_APP_API
      }/v2/maintainers`,
      {
        headers: authHeader(),
      }
    );
  }
  getOwners(maintainer) {
    return axios.get(
      `${
        process.env.VUE_APP_API
      }/owners?maintainer=${maintainer.toLowerCase()}`,

      {
        headers: authHeader(),
      }
    );
  }
  getOwnersV2(maintainer) {
    return axios.get(
      `${
        process.env.VUE_APP_API
      }/v2/owners?maintainer=${maintainer.toLowerCase()}`,

      {
        headers: authHeader(),
      }
    );
  }

  getOwnerShips(maintainer, ownerId) {
    return axios.get(
      `${process.env.VUE_APP_API}/ships?maintainer=${maintainer}&owner_id=${ownerId}`,

      {
        headers: authHeader(),
      }
    );
  }
  getOwnerShipsV2(maintainer, ownerId) {
    return axios.get(
      `${process.env.VUE_APP_API}/v2/ships?maintainer=${maintainer}&owner_id=${ownerId}`,

      {
        headers: authHeader(),
      }
    );
  }

  addSla(params) {
    return axios.put(
      `${process.env.VUE_APP_API}/add_sla`,params,

      {
        headers: authHeader(),
      }
    );
  }

  getShipById(params) {
    return axios.get(
      `${process.env.VUE_APP_API}/ships?maintainer=${params.maintainer}&id=${params.shipId}`,

      {
        headers: authHeader(),
      }
    );
  }
  getShipByIdV2(params) {
    return axios.get(
      `${process.env.VUE_APP_API}/v2/ships?maintainer=${params.maintainer}&id=${params.shipId}`,

      {
        headers: authHeader(),
      }
    );
  }

  getShipKpi(params) {
    let data = {
      date_from: params.dateFrom,
      date_to: params.dateTo,
      maintainer: params.maintainer,
      owner: params.ownerId,
      ship_imo: params.shipImo,
      ship_id: params.shipId,
    };
    return axios.post(
      `${process.env.VUE_APP_API}/get_ship_kpi`,

      data,
      {
        headers: authHeader(),
      }
    );
  }
}

export default new ShipsService();
