import store from "../store/index";

export default function authHeader() {
  let user = store.getters["auth/user"];

  if (user && user.token) {
    return {
      Authorization: "Bearer " + user.token,
      Accept: "application/json",
    };
  }
}
