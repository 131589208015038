import axios from "axios";
import authHeader from "./auth-header";

class EmcService {
  getEmcAllUsers() {
    return axios.get(`${process.env.VUE_APP_API}/emc_users/`, {
      headers: authHeader(),
    });
  }

  getEmcAllUsersAndActions() {
    return axios.get(`${process.env.VUE_APP_API}/get_emc_users/`, {
      headers: authHeader(),
    });
  }

  getEmcUserLog(logParams) {
    return axios.post(
      `${process.env.VUE_APP_API}/get_log`,
      {
        user_id: logParams.user_id,
        ...(logParams.isDateFrom && { date_from: logParams.date_from }),
        ...(logParams.isDateTo && { date_to: logParams.date_to }),
        ...(logParams.isAction && { action: logParams.action }),
        ...(logParams.withExceptions && { exception: logParams.exception }),
      },
      {
        headers: authHeader(),
      }
    );
  }

  registerEmcUser(emcNewUserData) {
    return axios.post(
      `${process.env.VUE_APP_API}/register`,
      { ...emcNewUserData },
      { headers: authHeader() }
    );
  }

  updateEmcUser(emcEditUserData) {
    return axios.put(
      `${process.env.VUE_APP_API}/update_emc_user`,
      { ...emcEditUserData },
      { headers: authHeader() }
    );
  }

  deleteEmcUser(id) {
    return axios.put(
      `${process.env.VUE_APP_API}/delete_emc_user?id=${id}`,
      {},
      {
        headers: authHeader(),
      }
    );
  }
  getEmcUserPresets() {
    return axios.get(`${process.env.VUE_APP_API}/get_emc_user_presets/`, {
      headers: authHeader(),
    });
  }
}

export default new EmcService();
