import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import { encrypt, decrypt } from "../functions/utils";
import { auth } from "./modules/auth.module";
import { rdm } from "./modules/rdm.module";
import { emc } from "./modules/emc.module";
import { kpi } from "./modules/kpi.module";
import { ships } from "./modules/ships.module";
import { tickets } from "./modules/tickets.module";
import { activation } from "./modules/activation.module";
import {userPermissions} from "./modules/user-permissions.module";


const authMutationsForVuexPersistence = [
  "auth/loginSuccess",
  "auth/logout",
  "auth/loginFailure"
];

const vuexLocal = new VuexPersistence({
  key: "userInfo",
  storage: {
    getItem: (key) =>
      JSON.parse(JSON.parse(decrypt(localStorage.getItem(key)))),
    setItem: (key, value) =>
      localStorage.setItem(key, encrypt(JSON.stringify(value))),
    removeItem: (key) => localStorage.removeItem(key),
  },
  reducer: (state) => state.auth.user,
  filter: (mutation) => authMutationsForVuexPersistence.includes(mutation.type),
});


const vuexLocal2 = new VuexPersistence({
  key: "exclusionEditData",
  storage: {
    getItem: (key) =>
      JSON.parse(JSON.parse((localStorage.getItem(key)))),
    setItem: (key, value) =>
      localStorage.setItem(key, (JSON.stringify(value))),
    removeItem: (key) => localStorage.removeItem(key),
  },
  reducer: (state) => state.ships.exForm,
},
);


const vuexUseAPI = new VuexPersistence({
  key: "permissionsUserData",
  storage: {
    getItem: (key) =>
      JSON.parse(JSON.parse((localStorage.getItem(key)))),
    setItem: (key, value) =>
      localStorage.setItem(key, (JSON.stringify(value))),
    removeItem: (key) => localStorage.removeItem(key),
  },
  reducer: (state) => state.userPermissions.selectedUser,
},
);

const store = createStore({
  modules: {
    auth,
    rdm,
    emc,
    kpi,
    ships,
    tickets,
    activation,
    userPermissions
  },
  plugins: [vuexLocal.plugin, vuexLocal2.plugin, vuexUseAPI.plugin],
});

export default store;
