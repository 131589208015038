import axios from "axios";
import authHeader from "./auth-header";

class KpiService {
  getDashboardList() {
    return axios.get(`${process.env.VUE_APP_API}/dashboard_kpi_list`, {
      headers: authHeader()
    });
  }

  getDashboardKpi(params) {
    return axios.get(
      `${process.env.VUE_APP_API}/dashboard_kpi_data?maintainer=${params.maintainer}&date_from=${params.date_from}&date_to=${params.date_to}&kpi=${params.kpi}`,
      {
        headers: authHeader()
      }
    );
  }

  getServerList(maintainer) {
    return axios.get(`${process.env.VUE_APP_API}/server_kpi_list?maintainer=${maintainer}`, {
      headers: authHeader()
    });
  }

  getServerKpi(params) {
    return axios.get(
      `${process.env.VUE_APP_API}/server_kpi_data?maintainer=${params.maintainer}&date_from=${params.date_from}&date_to=${params.date_to}&kpi=${params.kpi}`,
      {
        headers: authHeader()
      }
    );
  }

  getYearKPIPDF(params) {
    let data = {
      maintainer: typeof params !== "undefined" ? params.maintainer : null,
      ship_id: typeof params !== "undefined" ? params.ship_id : null,
      year: typeof params !== "undefined" ? params.year : null
    };
    return axios.post(
      `${process.env.VUE_APP_API}/get_year_kpi_report`,

      data,
      {
        headers: authHeader()
      }
    );
  }

  getResponseTimeKpi(kpiParams) {
    let date_from =
      typeof kpiParams !== "undefined" ? kpiParams.dateFrom : null;
    let date_to = typeof kpiParams !== "undefined" ? kpiParams.dateTo : null;
    let maintainer =
      typeof kpiParams !== "undefined" ? kpiParams.maintainer : null;

    return axios.get(
      `${process.env.VUE_APP_API}/response_time_kpi?date_from=${date_from}&date_to=${date_to}&maintainer=${maintainer}`,

      {
        headers: authHeader()
      }
    );
  }

  getImporterKpi(kpiParams) {
    let date_from =
      typeof kpiParams !== "undefined" ? kpiParams.dateFrom : null;
    let date_to = typeof kpiParams !== "undefined" ? kpiParams.dateTo : null;
    let maintainer =
      typeof kpiParams !== "undefined" ? kpiParams.maintainer : null;

    return axios.get(
      `${process.env.VUE_APP_API}/importer_kpi?date_from=${date_from}&date_to=${date_to}&maintainer=${maintainer}`,

      {
        headers: authHeader()
      }
    );
  }

  getActivationTimeKpi(kpiParams) {
    let date_from =
      typeof kpiParams !== "undefined" ? kpiParams.dateFrom : null;
    let date_to = typeof kpiParams !== "undefined" ? kpiParams.dateTo : null;
    let maintainer =
      typeof kpiParams !== "undefined" ? kpiParams.maintainer : null;

    return axios.get(
      `${process.env.VUE_APP_API}/activation_time_kpi_by_owner?date_from=${date_from}&date_to=${date_to}&maintainer=${maintainer}`,

      {
        headers: authHeader()
      }
    );
  }

  getWatchdogShipsKpiByOwner(kpiParams) {
    let date_from =
      typeof kpiParams !== "undefined" ? kpiParams.dateFrom : null;
    let date_to = typeof kpiParams !== "undefined" ? kpiParams.dateTo : null;
    let maintainer =
      typeof kpiParams !== "undefined" ? kpiParams.maintainer : null;

    return axios.get(
      `${process.env.VUE_APP_API}/watchdog_ships_kpi_by_owner?date_from=${date_from}&date_to=${date_to}&maintainer=${maintainer}`,

      {
        headers: authHeader()
      }
    );
  }

  getWatchdogShipKpi(kpiParams) {
    let dateFrom = typeof kpiParams !== "undefined" ? kpiParams.dateFrom : null;
    let dateTo = typeof kpiParams !== "undefined" ? kpiParams.dateTo : null;
    let maintainer =
      typeof kpiParams !== "undefined" ? kpiParams.maintainer : null;
    let shipImo = typeof kpiParams !== "undefined" ? kpiParams.shipImo : null;
    let shipId = typeof kpiParams !== "undefined" ? kpiParams.shipId : null;
    let ownerId = typeof kpiParams !== "undefined" ? kpiParams.ownerId : null;

    return axios.get(
      `${process.env.VUE_APP_API}/watchdog_kpi_per_ship?maintainer=${maintainer}&owner=${ownerId}&ship_imo=${shipImo}&start_time=${dateFrom}&end_time=${dateTo}&ship_id=${shipId}`,

      {
        headers: authHeader()
      }
    );
  }

  getWatchdogKpiExclusions(params) {
    let shipImo = typeof params !== "undefined" ? params.shipImo : null;
    let ownerId = typeof params !== "undefined" ? params.ownerId : null;

    return axios.get(
      `${process.env.VUE_APP_API}/watchdog_kpi_exclusions?owner_id=${ownerId}&ship_imo=${shipImo}`,

      {
        headers: authHeader()
      }
    );
  }

  updateWatchdogKpiExclusions(params) {
    let data = {
      id: typeof params !== "undefined" ? params.id : null,
      start_time:
        typeof params !== "undefined" && params.startTime
          ? params.startTime
          : null,
      end_time: typeof params !== "undefined" ? params.endTime : null,
      description: typeof params !== "undefined" ? params.description : null,
      deleted: typeof params !== "undefined" ? params.deleted : null,
      ship_imo: typeof params !== "undefined" ? params.shipImo : null,
      owner_id: typeof params !== "undefined" ? params.ownerId : null,
      maintainer: typeof params !== "undefined" ? params.maintainer : null,
      tickets: typeof params !== "undefined" ? params.tickets : null,
      exclusion_id: typeof params !== "undefined" ? params.exclusion_id : null
    };

    return axios.put(
      `${process.env.VUE_APP_API}/watchdog_kpi_exclusions`,
      data,
      {
        headers: authHeader()
      }
    );
  }

  createWatchdogKpiExclusions(params) {
    let data = {
      owner_id: typeof params !== "undefined" ? params.ownerId : null,
      ship_imo: typeof params !== "undefined" ? params.shipImo : null,
      start_time:
        typeof params !== "undefined" ? params.startTime.toString("") : null,
      end_time: typeof params !== "undefined" ? params.endTime : null,
      description: typeof params !== "undefined" ? params.description : null,
      maintainer: typeof params !== "undefined" ? params.maintainer : null,
      tickets: typeof params !== "undefined" ? params.tickets : null,
      exclusion_id: typeof params !== "undefined" ? params.exclusion_id : null
    };

    return axios.post(
      `${process.env.VUE_APP_API}/watchdog_kpi_exclusions`,
      data,
      {
        headers: authHeader()
      }
    );
  }

  createServicesExclusions(params) {
    return axios.post(
      `${process.env.VUE_APP_API}/watchdog_services_exclusions`,
      params,
      {
        headers: authHeader()
      }
    );
  }

  getTorquemeterFaultDetection(params) {
    return axios.get(
      `${process.env.VUE_APP_API}/torquemeter_fault_detection?imo=${params.imo}&date_from=${params.date_from}&date_to=${params.date_to}`,
      {
        headers: authHeader()
      }
    );
  }
}

export default new KpiService();
