import axios from "axios";
import authHeader from "./auth-header";

class RdmService {
  getRdmAllUsers() {
    return axios.get(`${process.env.VUE_APP_API}/rdm_users/`, {
      headers: authHeader(),
    });
  }

  refreshRdmAllUsers() {
    return axios.get(`${process.env.VUE_APP_API}/refresh_rdm_users/`, {
      headers: authHeader(),
    });
  }

  getRdmUserShips(id) {
    return axios.get(`${process.env.VUE_APP_API}/user_ships?id=${id}`, {
      headers: authHeader(),
    });
  }

  getRdmOwnerShips(params) {
    return axios.get(
      `${process.env.VUE_APP_API}/owner_ships/?maintainer=${params.maintainer}&owner_id=${params.ownerId}`,
      {
        headers: authHeader(),
      }
    );
  }

  refreshOwners() {
    return axios.get(`${process.env.VUE_APP_API}/refresh_owners`, {
      headers: authHeader(),
    });
  }

  getRdmNewUser() {
    return axios.get(`${process.env.VUE_APP_API}/new_user`, {
      headers: authHeader(),
    });
  }

  createRdmUser(rdmNewUserData) {
    return axios.post(
      `${process.env.VUE_APP_API}/create_user`,
      {
        userData: rdmNewUserData.userData,
        userShips: rdmNewUserData.userShips,
        removeShips: rdmNewUserData.removeShips,
      },
      { headers: authHeader() }
    );
  }

  updateRdmUser(rdmUpdateUserData) {
    return axios.put(
      `${process.env.VUE_APP_API}/update_user`,
      {
        userData: rdmUpdateUserData.userData,
        userShips: rdmUpdateUserData.userShips,
        removeShips: rdmUpdateUserData.removeShips,
      },
      { headers: authHeader() }
    );
  }

  updateRdmUserShips(rdmUpdateUserShips) {
    return axios.put(
      `${process.env.VUE_APP_API}/update_ships`,
      {
        userData: rdmUpdateUserShips.userData,
        userShips: rdmUpdateUserShips.userShips,
        removeShips: rdmUpdateUserShips.removeShips,
      },
      { headers: authHeader() }
    );
  }

  deleteRdmUser(id) {
    return axios.put(
      `${process.env.VUE_APP_API}/delete_rdm_user?id=${id}`,
      {},
      { headers: authHeader() }
    );
  }
}

export default new RdmService();
