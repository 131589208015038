import Activation from "../../services/activation.service.js";

const activationInitializeState = {
  emails: null,

  toastData: {
    message: "",
    title: "",
    type: "",
  },
};

export const activation = {
  namespaced: true,
  state: activationInitializeState,
  actions: {
    getEmails({ commit }, deleted) {
      return Activation.getEmails(deleted)
        .then(
          (result) => {
            commit("getEmailsSuccess", result.data.emails);
            return Promise.resolve(result);
          },
          (error) => {
            commit("getEmailsFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateToastData", {
            title: "Error!",
            msg: errorMsg,
            type: "error",
          });
        });
    },
    getEmailsRules({ commit }) {
      return Activation.getEmailsRules()
        .then(
          (EmailsRules) => {
            commit("getEmailsRulesSuccess", EmailsRules.data.rules);
            return Promise.resolve(EmailsRules);
          },
          (error) => {
            commit("getEmailsRulesFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("activation/updateEmailsRulesMsg", errorMsg);
        });
    },
    updateEmailsRules({ commit }, data) {
      return Activation.updateEmailsRules(data)
        .then(
          (result) => {
            commit("updateToastData", {
              title: "Success!",
              msg: "Emails rules update success",
              type: "success",
            });
            return Promise.resolve(result);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateToastData", {
            title: "Error!",
            msg: errorMsg,
            type: "error",
          });
        });
    },
    deleteEmailsRules({ commit }, data) {
      return Activation.deleteEmailsRules(data)
        .then(
          (result) => {
            commit("updateToastData", {
              title: "Success!",
              msg: "Email rules delete success",
              type: "success",
            });
            return Promise.resolve(result);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateToastData", {
            title: "Error!",
            msg: errorMsg,
            type: "error",
          });
        });
    },
    changeEmailsVisibility({ commit }, data) {
      return Activation.changeEmailsVisibility(data)
        .then(
          (result) => {
            commit("updateToastData", {
              title: "Success!",
              msg:
                data.deleted === 1
                  ? "Email delete success"
                  : "Email restore success",
              type: "success",
            });
            return Promise.resolve(result);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateToastData", {
            title: "Error!",
            msg: errorMsg,
            type: "error",
          });
        });
    },
    computeKpi({ commit }, data) {
      return Activation.computeKpi(data)
        .then(
          (result) => {
            commit("updateToastData", {
              title: "Success!",
              msg: "Kpi computed successfully.",
              type: "success",
            });
            return Promise.resolve(result);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateToastData", {
            title: "Error!",
            msg: errorMsg,
            type: "error",
          });
        });
    },
  },
  mutations: {
    getEmailsSuccess(state, emails) {
      state.emails = emails;
    },
    getEmailsFailure(state) {
      state.emails = null;
    },
    getEmailsRulesSuccess(state, emailsRules) {
      state.emailsRules = emailsRules;
    },

    getEmailsRulesFailure(state) {
      state.emailsRules = null;
    },
    updateToastData(state, data) {
      if (data) {
        state.toastData = {
          title: data.title,
          message: data.msg,
          type: data.type,
        };
      } else {
        state.toastData = {};
      }
    },
  },
  getters: {
    emails: (state) => {
      return state.emails;
    },
    toastData: (state) => {
      return state.toastData;
    },
    emailsRules: (state) => {
      return state.emailsRules;
    }
  },
};
