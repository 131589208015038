import axios from "axios";
import authHeader from "./auth-header";

class TicketsService {
  getTickets(data) {
    let close = data.closed ? 1 : 0;
    return axios.post(`${process.env.VUE_APP_API}/tickets?closed=${close}`, data, 
        {
      headers: authHeader(),
    });
  }
  updateTicket(data) {
    return axios.put(`${process.env.VUE_APP_API}/tickets`, data, {
      headers: authHeader(),
    });
  }
  mergeTickets(data) {
    return axios.post(`${process.env.VUE_APP_API}/tickets_merge`, data, {
      headers: authHeader(),
    });
  }
  getUuid() {
    return axios.get(`${process.env.VUE_APP_API}/get_uuid`, {
      headers: authHeader(),
    });
  }
  createTicket(ticket) {
      return axios.post(`${process.env.VUE_APP_API}/create_ticket`, ticket, {
      headers: authHeader(),
    });
  }

  getTicketsforShip(data) {
    return axios.post(`${process.env.VUE_APP_API}/get_tickets_for_exclusion`, data, {
      headers: authHeader(),
    });
  }

  closeTicket(data) {
    return axios.post(`${process.env.VUE_APP_API}/close_ticket`, data, {
      headers: authHeader(),
    });
  }

  deleteTicket(data) {
    return axios.post(`${process.env.VUE_APP_API}/delete_ticket`, data, {
      headers: authHeader(),
    });
  }
  getTicketPropositions() {
    return axios.get(`${process.env.VUE_APP_API}/ticket_propositions`, {
      headers: authHeader(),
    });
  }
  createTicketFromPropositions(data) {
    return axios.post(
      `${process.env.VUE_APP_API}/create_ticket_from_proposition`,
      data,
      {
        headers: authHeader(),
      }
    );
  }
  mergePropositionWithTicket(data){
    return axios.post (
      `${process.env.VUE_APP_API}/merge_proposition_with_ticket`,
      data,
      {
        headers: authHeader(),
      }
    );
  }
  removeProposition(data){
    return axios.post (
      `${process.env.VUE_APP_API}/remove_proposition`,
      data,
      {
      headers: authHeader(),
    });
  }
  getTicketsLastUpdate(){
    return axios.get (
      `${process.env.VUE_APP_API}/tickets_check_last_update`,
      {
        headers: authHeader(),
      });
  }
}

export default new TicketsService();
