<template>
  <DataTable
    :value="exclusions"
    :paginator="true"
    :rows="2"
    class="kpi-exclusions-table"
    dataKey="ship_id"
    :rowHover="true"
    v-model:filters="filters"
    filterDisplay="menu"
    :loading="loading"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    :globalFilterFields="['start_time', 'end_time', 'description']"
    :scrollable="true"
    scrollHeight="flex"
  >
    <template #header>
      <div class="p-d-flex p-jc-between p-ai-center">
        <div><h3 class="p-m-0">KPI exclusions</h3></div>

        <div style="display:flex">
          <button
            v-if="userHasAll(['view-kpi-standard', 'edit-kpi'])"
            class="p-button p-button-outlined go-back-button add-new-exclusion-btn"
            @click="handleEditUser(route.query.ship_imo, route.query.ship_id, route.query.owner_id, route.query.maintainer)"
          >
            Add New
          </button>
          <div class="p-inputgroup">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="Keyword Search"
                style="min-width: 15rem"
              />
            </span>
          </div>
        </div>
      </div>
    </template>
    <template #empty>No exclusions found.</template>
    <template #loading>Loading exclusions. Please wait.</template>

    <Column
      field="start_time"
      header="Start time"
      headerClass="p-column-header"
      sortable
      dataType="date"
      style="
        min-width: 10%;
        max-width: 25%;
        display: flex;
        flex: 1 1 15%;
        justify-content: flex-start;
        overflow: hidden;
      "
    ></Column>

    <Column
      field="end_time"
      sortable
      headerClass="p-column-header p-default-cursor"
      :showFilterMatchModes="false"
      style="
        min-width: 10%;
        max-width: 25%;
        display: flex;
        flex: 1 1 15%;
        justify-content: flex-start;
        overflow: hidden;
      "
    >
      <template #header>
        <div class="p-table-center-flex">End time</div>
      </template>
      <template #body="{ data }">
        <div class="p-d-flex p-jc-between">
          <span v-if="data.end_time">{{ data.end_time }}</span>
          <span v-else class="current-exclusion">CURRENT</span>
        </div>
      </template>
    </Column>

    <Column
      field="description"
      header="Description"
      headerClass="p-column-header"
      sortable
      style="
        min-width: 10%;
        max-width: 50%;
        display: flex;
        flex: 1 1 15%;
        justify-content: flex-start;
        overflow: hidden;
    "
    >
      <template #body="{ data }">
        <div
          :class="data.description && data.description.length >= 30
            ? 'p-overflow'
            : 'p-white-space-no-wrap'
            "
          v-tooltip.left="data.description && data.description.length >= 30
            ? data.description
            : ''
            "
        >
          {{ data.description }}
        </div>
      </template>
    </Column>

    <Column
      v-if="userHasAny(['edit-kpi','view-tickets'])"
      field="actions"
      headerClass="p-column-header p-default-cursor"
      :showFilterMatchModes="false"
      style="
        min-width: 10%;
        max-width: 15%;
        display: flex;
        flex: 1 1 10%;
        justify-content: center;
      "
    >
      <template #header>
        <div class="p-table-center-flex">Actions</div>
      </template>
      <template #body="{ data }">
        <div class="p-d-flex p-jc-between">
          <div v-tooltip.top="'Edit'"
               class="p-mr-2"
               v-if="userHasAll(['edit-kpi'])"
          >
            <font-awesome-icon
              :icon="['fas', 'edit']"
              class="p-cursor-pointer p-color-edit"
              @click="editExclusion(
                route.query.ship_imo,
                route.query.ship_id,
                route.query.owner_id,
                route.query.maintainer,
                route.query.exclusion_id,
                route.query.start_time,
                route.query.end_time,
                data)"
            />

          </div>
          <div
            v-if="userHasAll(['view-tickets'])"
            :class="data.id && data.id.length >= 20 ? 'p-overflow' : 'p-white-space-no-wrap'"
            v-tooltip.left="'Ticket ID:\n' + data.tickets.join(`\n`)"
            @click="goToTicket(data.tickets)"
            class="p-cursor-pointer"
            style=
              "
            transition: none;
            justify-content: center;
            "
          >
            <font-awesome-icon
              v-if="data.tickets && data.tickets.length > 0"
              :icon="['fas', 'fingerprint']"
              class="p-cursor-pointer p-color-edit"
              style="margin: 0 5px"
            />
          </div>
        </div>
      </template>
    </Column>
    <template #paginatorLeft>
      <div class="p-d-flex p-jc-start p-ai-center"></div>
    </template>
  </DataTable>
</template>

<script>
import { ref } from "vue";
import { FilterMatchMode } from "primevue/api";
import store from "../../store";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useConfirm } from "primevue/useconfirm";
import { tickets } from "../../store/modules/tickets.module";


export default {
  name: "WatchdogKpiExclusionsTable",
  props: {
    exclusions: {
      required: true,
      default: []
    },
    loading: {
      required: true,
      default: false
    }
  },

  setup(props, { emit }) {


    const store = useStore();
    const userIsAdmin = store.getters["auth/userIsAdmin"];
    const perm = store.getters["auth/permissions"];
    const router = useRouter();
    const confirm = useConfirm();
    const route = useRoute();
    const selectedTickets = ref();
    const tickets = ref();
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const handleEditUser = async (shipImo, ship_id, owner_id, maintainer) => {
      const param = {
        dateTo: null,
        dateFrom: null,
        description: null
      };

      store.dispatch("ships/setExclusionData", param);

      router.push({
        path: "/ship/profile/addNew",
        query: {
          maintainer: maintainer.toLowerCase(),
          owner_id: owner_id,
          ship_id: ship_id,
          ship_imo: shipImo,
          date_from: route.query.date_from,
          date_to: route.query.date_to
        }
      });
    };

    const editExclusion = (shipImo, ship_id, owner_id, maintainer, exclusion_id, start_time, end_time, data) => {

      const param = {
        dateTo: data.end_time,
        dateFrom: data.start_time,
        description: data.description,
        id: data.id,
        ship_imo: shipImo,
        tickets: tickets
      };

      store.dispatch("ships/setExclusionData", param);

      router.push({
        path: "/ship/profile/addNew",
        query: {
          maintainer: maintainer.toLowerCase(),
          owner_id: owner_id,
          ship_id: ship_id,
          ship_imo: shipImo,
          exclusion_id: data.id,
          date_from: route.query.date_from,
          date_to: route.query.date_to
        }
      });

    };

    const showEditExclusionForm = (clearState) => {
      emit("showEditExclusionForm", clearState);
    };


    const goToTicket = async (tickets) => {


      await store.dispatch("tickets/setFilteredTickets", tickets);

      router.push({
        path: "/tickets/listCustomTickets"
      }).then(() => {
        store.dispatch("tickets/setFilteredTickets", null);
      });
    };

    function userHasAny(permissions) {
      if (userIsAdmin) {
        return true;
      }
      if (perm === null || typeof perm === "undefined") {
        return false;
      }
      if (permissions === null || typeof permissions === "undefined") {
        return false;
      }
      for (let j = 0; j < permissions.length; j++) {
        if (perm.map((x) => x.key).includes(permissions[j])) {
          return true;
        }
      }
      return false;
    }

    function userHasAll(permissions) {
      if (userIsAdmin) {
        return true;
      }
      if (perm === null || typeof perm === "undefined") {
        return false;
      }
      if (permissions === null || typeof permissions === "undefined") {
        return false;
      }
      for (let j = 0; j < permissions.length; j++) {
        if (!perm.map((x) => x.key).includes(permissions[j])) {
          return false;
        }
      }
      return true;
    }

    return {
      showEditExclusionForm,
      editExclusion,
      goToTicket,
      handleEditUser,
      userHasAny,
      userHasAll,

      route,
      userIsAdmin,
      filters
    };
  }
};
</script>

<style scoped>

</style>
