import axios from "axios";
import authHeader from "./auth-header";

class ActivationService {
  getEmails(deleted) {
    let del = deleted ? 1 : 0;

    return axios.get(
      `${process.env.VUE_APP_API}/ship_data_emails?deleted=${del}`,
      {
        headers: authHeader(),
      }
    );
  }
  changeEmailsVisibility(data) {
    return axios.put(`${process.env.VUE_APP_API}/ship_data_emails`, data, {
      headers: authHeader(),
    });
  }
  getEmailsRules() {
    return axios.get(
      `${process.env.VUE_APP_API}/email_rules`,
      {
        headers: authHeader(),
      }
    );
  }
  updateEmailsRules (data) {
    return axios.put(`${process.env.VUE_APP_API}/update_emails_rules`, data, {
      headers: authHeader(),
    });
  }
  deleteEmailsRules (data) {
    return axios.post(`${process.env.VUE_APP_API}/delete_emails_rules`, data, {
      headers: authHeader(),
    });
  }
  computeKpi(data) {
    return axios.post(
      `${process.env.VUE_APP_API}/compute_activation_time_kpi`,
      data,
      {
        headers: authHeader(),
      }
    );
  }
}

export default new ActivationService();
