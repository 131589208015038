import axios from "axios";
import authHeader from "./auth-header";

class AuthService {
  login(user) {
    return axios
      .post(
        `${process.env.VUE_APP_API}/login`,
        {
          email: user.email,
          password: user.password,
          is_admin: false,
        },
        {
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  logout() {
    return axios
      .post(`${process.env.VUE_APP_API}/logout`, null, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  isSessionActive(userLogin) {
    return axios
      .post(`${process.env.VUE_APP_API}/session_check`, userLogin, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

export default new AuthService();
