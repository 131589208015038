import EmcService from "../../services/emc.service";

const emcInitialState = {
  emcAllUsersAndActions: {
    actions: [],
    users: [],
    date_between: {},
    notifications: []
  },
  emcUser: {},
  emcEditUser: {},
  emcUsersLog: {},
  emcApiErrorMsg: "",
  emcSessionTimePassed: 0,
  emcUserPresets: []
};

export const emc = {
  namespaced: true,
  state: emcInitialState,
  actions: {
    getEmcAllUsers({ commit }) {
      return EmcService.getEmcAllUsersAndActions()
        .then(
          (emcAllUsersAndActions) => {
            commit("getEmcAllUsersAndActionsSuccess", emcAllUsersAndActions);
            return Promise.resolve(emcAllUsersAndActions);
          },
          (error) => {
            commit("getEmcAllUsersAndActionsFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateEmcApiErrorMsg", errorMsg);
        });
    },

    getEmcUserLog({ commit }, logParams) {
      return EmcService.getEmcUserLog(logParams)
        .then(
          (emcUserLog) => {
            commit("getEmcUserLogSuccess", emcUserLog);
            return Promise.resolve(emcUserLog);
          },
          (error) => {
            commit("getEmcUserLogFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateEmcApiErrorMsg", errorMsg);
        });
    },

    getEmcNewUser({ commit }) {
      return commit("getEmcNewUserSuccess");
    },

    registerEmcUser({ commit }, emcNewUserData) {
      return EmcService.registerEmcUser(emcNewUserData)
        .then(
          (emcNewUserDataFromAPI) => {
            commit("registerEmcUserSuccess", emcNewUserDataFromAPI);
            return Promise.resolve(emcNewUserDataFromAPI);
          },
          (error) => {
            commit("registerEmcUserFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          let errorMsg = error.response.data.message;
          if (error.response.data.errors.email) {
            error.response.data.errors.email.forEach(
              (err) => (errorMsg = errorMsg.concat(" - ", err))
            );
          }
          if (error.response.data.errors.password) {
            error.response.data.errors.password.forEach(
              (err) => (errorMsg = errorMsg.concat(" - ", err))
            );
          }
          commit("updateEmcApiErrorMsg", errorMsg);
        });
    },

    updateEmcUser({ commit }, emcEditUserData) {
      return EmcService.updateEmcUser(emcEditUserData)
        .then(
          (emcEditUserDataFromAPI) => {
            commit("updateEmcUserSuccess", emcEditUserDataFromAPI);
            return Promise.resolve(emcEditUserDataFromAPI);
          },
          (error) => {
            commit("updateEmcUserFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateEmcApiErrorMsg", errorMsg);
        });
    },

    deleteEmcUser({ commit }, id) {
      return EmcService.deleteEmcUser(id)
        .then(
          (emcDeletedUser) => {
            return Promise.resolve(emcDeletedUser);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateEmcApiErrorMsg", errorMsg);
        });
    },
    getEmcUserPresets({ commit }) {
      return EmcService.getEmcUserPresets()
        .then(
          (response) => {
            commit("emcUserPresetsSuccess", response);
            return Promise.resolve(response);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    updateSessionTimePassed({ commit }) {
      commit("updateSessionTimePassed");
    },

    setSessionTimePassed({ commit }, time) {
      commit("setSessionTimePassed", time);
    },

    resetSessionTimePassed({ commit }) {
      commit("resetSessionTimePassed");
    }
  },
  mutations: {
    getEmcAllUsersAndActionsSuccess(state, emcAllUsersAndActions) {
      state.emcAllUsersAndActions = emcAllUsersAndActions.data;
    },

    getEmcAllUsersAndActionsFailure(state) {
      state.emcAllUsersAndActions = null;
    },

    getEmcUserLogSuccess(state, emcUserLog) {
      state.emcUsersLog = emcUserLog.data;
    },

    getEmcUserLogFailure(state) {
      state.emcUsersLog = null;
    },

    getEmcNewUserSuccess(state) {
      state.emcUser = {
        id: "",
        email: "",
        is_admin: false,
        preset_id: 0,
        notification: []
      };
    },
    getEmcUserById(state, id) {
      const emcUserArray = state.emcAllUsersAndActions.users.filter(
        (v) => v.id === Number(id)
      );
      state.emcUser = emcUserArray[0];
    },

    registerEmcUserSuccess(state, emcNewUserDataFromAPI) {
      state.emcEditUser = emcNewUserDataFromAPI.data;
    },
    registerEmcUserFailure(state) {
      state.emcEditUser = null;
    },

    updateEmcUserSuccess(state, emcUpdateUserDataFromAPI) {
      state.emcEditUser = emcUpdateUserDataFromAPI.data;
    },
    updateEmcUserFailure(state) {
      state.emcEditUser = null;
    },

    updateEmcApiErrorMsg(state, errorMsg) {
      state.emcApiErrorMsg = errorMsg;
    },

    updateSessionTimePassed(state) {
      state.emcSessionTimePassed += 1;
    },

    setSessionTimePassed(state, time) {
      state.emcSessionTimePassed = time;
    },

    resetSessionTimePassed(state) {
      state.emcSessionTimePassed = 0;
    },
    emcUserPresetsSuccess(state, response) {
      state.emcUserPresets = response.data.presets;
    }
  },
  getters: {
    emcAllUsers: (state) => {
      return state.emcAllUsersAndActions && state.emcAllUsersAndActions.users;
    },

    emcAllActions: (state) => {
      return state.emcAllUsersAndActions && state.emcAllUsersAndActions.actions;
    },

    emcAllDateBetween: (state) => {
      return (
        state.emcAllUsersAndActions && state.emcAllUsersAndActions.date_between
      );
    },

    emcUserData: (state) => {
      return state.emcUser && state.emcUser;
    },

    emcUserLog: (state) => {
      return state.emcUsersLog && state.emcUsersLog;
    },

    emcApiErrorMsg: (state) => {
      return state.emcApiErrorMsg && state.emcApiErrorMsg;
    },

    emcSessionTimePassed: (state) => {
      return state.emcSessionTimePassed;
    },

    emcUserNotifications: (state) => {
      return state.emcAllUsersAndActions.notifications;
    },
    emcUserPresets: (state) => {
      return state.emcUserPresets ?? [];
    }
  }
};
