import axios from "axios";
import authHeader from "./auth-header";

class UserPermissionsService {
  getAPIUsers(maintainer) {
    return axios.get(`${process.env.VUE_APP_API}/api_user?maintainer=${maintainer}`, {
      headers: authHeader(),
    });
  }
  createAPIUser(data) {
    return axios.post(`${process.env.VUE_APP_API}/api_user`, data, {
      headers: authHeader(),
    });
  }
  updateAPIUser(data) {
    return axios.put(`${process.env.VUE_APP_API}/api_user`, data, {
      headers: authHeader(),
    });
  }
  getAPITables(maintainer) {
    return axios.get(`${process.env.VUE_APP_API}/api_tables?maintainer=${maintainer}`, {
      headers: authHeader(),
    });
  }
  createAPIUserPermissions(data) {
    return axios.post(`${process.env.VUE_APP_API}/api_permissions`, data, {
      headers: authHeader(),
    });
  }
  getAPIColumns(maintainer, table_name, imo) {
    return axios.get(`${process.env.VUE_APP_API}/api_columns?maintainer=${maintainer}&table_name=${table_name}&imo=${imo}`, {
      headers: authHeader(),
    });
  }
  updateAPIUserPermissions(data) {
    return axios.put(`${process.env.VUE_APP_API}/api_permissions`, data, {
      headers: authHeader(),
    });
  }
  getAPIUserPermissions(maintainer, user_id) {
    return axios.get(`${process.env.VUE_APP_API}/api_permissions?maintainer=${maintainer}&user_id=${user_id}`, {
      headers: authHeader(),
    });
  }
}


export default new UserPermissionsService();
