<template>
  <div class="p-hour-picker">
    <button class="p-link" type="button" @click="timeUp()">
      <span class="pi pi-chevron-up"></span>
    </button>
    <span>
      <input
        :value="time"
        type="number"
        :min="minValue"
        :max="maxValue"
        style="width: 4rem; text-align: center"
        @input="updateValue($event)"
        @keydown="timeKeydown($event)"
        :class="['p-inputtext p-component']"
      />
    </span>
    <button class="p-link" type="button" @click="timeDn()">
      <span class="pi pi-chevron-down"></span>
    </button>
  </div>
  <div class="p-separator" v-if="isSeparator">
    <span>:</span>
  </div>
</template>

<script>
import { ref, getCurrentInstance, watch } from "vue";
import { timeKeydown } from "../../functions/utils";

export default {
  name: "TimePicker",
  props: {
    defaultValue: {
      type: Number,
      required: true,
      default: 0,
    },
    minValue: {
      type: Number,
      required: true,
      default: 0,
    },
    maxValue: {
      type: Number,
      required: true,
      default: 23,
    },
    maxLength: {
      type: Number,
      required: false,
      default: 2,
    },
    stepUp: {
      type: Number,
      required: false,
      default: 1,
    },
    stepDn: {
      type: Number,
      required: false,
      default: 1,
    },
    isSeparator: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const time = ref(props.defaultValue);
    const instance = getCurrentInstance();

    const timeUp = () => {
      if (
        time.value + props.stepUp > props.minValue &&
        time.value + props.stepUp <= props.maxValue
      ) {
        time.value = time.value + props.stepUp;
      }
      if (time.value + props.stepUp > props.maxValue) {
        time.value = props.maxValue;
      }
    };

    const timeDn = () => {
      if (
        time.value - props.stepDn >= props.minValue &&
        time.value - props.stepDn < props.maxValue
      ) {
        time.value = time.value - props.stepDn;
      }
      if (time.value - props.stepDn < props.minValue) {
        time.value = props.minValue;
      }
    };

    const updateValue = (event) => {
      const value = event.target.value;
      const valueString = String(event.target.value);
      if (
        valueString.length <= props.maxLength &&
        value >= props.minValue &&
        value <= props.maxValue
      ) {
        time.value = value;
      }
      instance.proxy.$forceUpdate();
    };

    watch(time, (value, prevValue) => {
      if (value !== prevValue) {
        emit("changedTime", time.value);
      }
    });

    return {
      time,
      timeUp,
      timeDn,
      timeKeydown,
      updateValue,
    };
  },
  emits: ["changedTime"],
};
</script>

<style scoped lang="scss">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
